import React from "react";
import SentMail from "../../components/Loader/SentMail";
import ButtonSubmit from "../../components/Buttons/ButtonStyle";

const ValidationMessage = ({ handleAssignPackage, status }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="successFullMessage-container d-flex justify-content-center align-items-center">
        <div
          style={{
            width: 500,
            backgroundColor: "white",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <div className="mt-4">
            <div
              className="w-100 d-flex justify-content-center"
              style={{
                userSelect: "none",
                color: "#f68a20",
                fontSize: 18,
                fontWeight: "800",
              }}
            >
              To complete your registration
            </div>
            <div className="w-100 d-flex justify-content-center ">
              <SentMail title="Please click below" />
            </div>
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <buton
              className="btn"
              style={{
                fontWeight: "600",
                color: "white",
                borderColor: "#f68a20",
                borderWidth: "2px",
                borderStyle: "solid",
                borderRadius: 15,
                backgroundColor: "#f68a20",
              }}
              onClick={() => handleAssignPackage()}
            >
              {status ? "Loading..." : "OK"}
            </buton>
            {/* <ButtonSubmit
              Title={status ? "Loading..." : "OK"}
              BackgroundColor="#f68a20"
              ColorText="white"
              BorderColor="#f68a20"
              borderRadius={15}
              handleOnclick={handleAssignPackage}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidationMessage;
