import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonRsvp from "../../../Buttons/ButtonRsvp";
// import { detectDeviceType } from "../../../../utils/deviceType";

const Header = ({
  eventDetails,
  deviceType,
  isSessions,
  isSpeaker,
  isFaq,
  isSponsors,
  isBtnShow,
}) => {
  const Navigate = useNavigate();

  // console.log("eventDetails?.eventStatus....", eventDetails?.eventStatus);

  return (
    <React.Fragment>
      <div
        className="w-100 d-flex justify-content-between align-items-center px-4"
        style={{ height: 65, backgroundColor: "white" }}
      >
        <div className="" style={{ height: 65 }}>
          {eventDetails?.logo ? (
            <img
              className="w-100 h-100"
              src={`${eventDetails?.logo}`}
              alt="Company Logo"
              onClick={() => {
                Navigate(-1);
              }}
            />
          ) : null}
        </div>
        <div className="d-flex align-items-center fw-bold gap-3">
          {deviceType === "Desktop" ? (
            <div className="d-flex align-items-center gap-2">
              <div className="">
                <a
                  className="header-section--menu--list__item__link"
                  href="#about"
                >
                  About
                </a>
              </div>
              {!isSessions ? (
                <div className="">
                  <a
                    className="header-section--menu--list__item__link"
                    href="#schedule"
                  >
                    Schedule
                  </a>
                </div>
              ) : null}

              {!isSpeaker ? (
                <div className="">
                  <a
                    className="header-section--menu--list__item__link"
                    href="#speakers"
                  >
                    Speakers
                  </a>
                </div>
              ) : null}

              {!isSponsors ? (
                <div className="">
                  <a
                    className="header-section--menu--list__item__link"
                    href="#sponsors"
                  >
                    Sponsors
                  </a>
                </div>
              ) : null}

              {isFaq ? (
                <div className="">
                  <a
                    className="header-section--menu--list__item__link"
                    href="#faq"
                  >
                    FAQ's
                  </a>
                </div>
              ) : null}
            </div>
          ) : null}
          {eventDetails?.venueMap ? (
            <div className="">
              <a
                className="header-section--menu--list__item__link"
                href="#location"
              >
                Location
              </a>
            </div>
          ) : null}
          {isBtnShow && eventDetails?.eventStatus === "Ongoing" ? (
            <div className="">
              <ButtonRsvp
                Title="Enter Event"
                leftColor="#f7983c"
                rightColor="#ff7c6e"
                ColorText="white"
                BorderColor="transparent"
                borderRadius={50}
                handleOnclick={() => {
                  if (eventDetails?.eventType?.includes("Webinar")) {
                    Navigate(
                      `/${eventDetails?.eventName?.replace(
                        / /g,
                        "-"
                      )}/webinar/${eventDetails?._id}`
                    );
                  }
                  if (eventDetails?.eventType?.includes("Conference")) {
                    Navigate(
                      `/${eventDetails?.eventName?.replace(
                        / /g,
                        "-"
                      )}/conference/${eventDetails?._id}`
                    );
                  }
                  if (eventDetails?.eventType?.includes("Exhibition")) {
                    Navigate(
                      `/${eventDetails?.eventName?.replace(
                        / /g,
                        "-"
                      )}/exhibition/${eventDetails?._id}`
                    );
                  }
                  if (eventDetails?.eventType?.includes("Expo")) {
                    Navigate(
                      `/${eventDetails?.eventName?.replace(/ /g, "-")}/expo/${
                        eventDetails?._id
                      }`
                    );
                  }
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Header;
