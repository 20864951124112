import React, { useState, useEffect } from "react";
import { eventCountDown } from "../../../../utils/eventCountDown";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";
import { useNavigate } from "react-router-dom";
import Divider from "./Divider";
// import GradientComponent from "../../GradientColor/GradientComponent";
import ButtonGradient from "../../../Buttons/ButtonGradient";

import HouseRules from "./HouseRules";
const About = ({ eventDetails, userEmail }) => {
  const [countDown, setCountDown] = useState({ status: null, count: null });
  const Navigate = useNavigate();

  useEffect(() => {
    if (eventDetails) {
      const intervalId = setInterval(() => {
        const count = eventCountDown(
          eventDetails?.startDate,
          eventDetails?.endDate
        );
        setCountDown(count);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [eventDetails]);

  const adminRoles = [
    "event_organizer",
    "co_event_organizer",
    "speaker",
    "session_coordinator",
    "host",
  ];

  const IsAttendee = eventDetails?.attendees?.includes(userEmail);
  const IsAdmin = eventDetails?.personnel?.some(
    (person) => person?.email === userEmail && adminRoles?.includes(person.role)
  );

  return (
    <React.Fragment>
      <div className="w-100 pt-3" style={{ backgroundColor: "white" }}>
        <div className="w-100 mt-3 d-flex justify-content-center">
          <div style={{ width: "67%" }}>
            {!IsAttendee ? (
              <>
                <div className="w-100 d-flex justify-content-center">
                  <h1
                    id="schedule"
                    className="d-flex justify-content-center"
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    House Rules
                  </h1>
                </div>
                <Divider />
                <HouseRules />
              </>
            ) : null}
          </div>
        </div>
        <div
          className="mt-2 mb-5 w-100 d-flex justify-content-center"
          // style={{ backgroundColor: "red" }}
        >
          <ButtonSubmit
            Title={IsAdmin ? "Go backstage" : "Join now"}
            BackgroundColor="#f68a20"
            ColorText="#fff"
            BorderColor="#f68a20"
            borderRadius={10}
            handleOnclick={() => {
              if (IsAttendee) {
                Navigate(
                  `/${eventDetails?.eventName?.replace(/ /g, "-")}/w/live/${
                    eventDetails?._id
                  }`
                );
              }
              if (IsAdmin) {
                Navigate(
                  `/${eventDetails?.eventName?.replace(/ /g, "-")}/w/${
                    eventDetails?._id
                  }`
                );
              }
            }}
            pending={false}
          />
        </div>
        <div className="mb-5"></div>
      </div>
    </React.Fragment>
  );
};

export default About;
