import React, { useState } from "react";
import OffCanvas from "../../OffCanvas/OffCanvas";
import SearchInput from "../../SerachInput/SearchInput";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import useCustomMutation from "../../../Hooks/useCustomMutation";
import ModalDelete from "../../Modal/ModalDelete";
import Nothing from "../../Loader/Contacts";
import ButtonIcon from "../../Buttons/ButtonIcon";
import DataTable from "react-data-table-component";
import { FaPlus } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { CgAttachment } from "react-icons/cg";
import { BsPersonFillAdd } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import Tooltip from "../../../utils/toolTip";
import AddSession from "./AddSession";
import ViewSession from "./ViewSession";
import EditSession from "./EditSession";
import AttachSessionDocument from "./AttachSessionDocument";
import AssignUser from "./AssignUser";
import DeleteForm from "./DeleteForm";

const Sessions = ({ eventDetails, token, userID }) => {
  const [isAddSession, setIsAddSession] = useState(false);
  const [isViewSession, setIsViewSession] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDocAttach, setIsDocAttach] = useState(false);
  const [isUserAssign, setUserAssign] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const [search, setSearch] = useState(null);
  const [sessionID, setSessionID] = useState(null);
  const [sessionDetails, setSessionDetails] = useState(null);

  const key = ["mySessions", eventDetails?._id];

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomFecthQuery(
    key,
    `${process.env.REACT_APP_API}sessions/event/${eventDetails?._id}`,
    token,
    {
      enabled: !!eventDetails?._id,
    }
  );

  const tog_viewSession = (details) => {
    setIsViewSession(!isViewSession);
    setSessionDetails(details);
  };

  const tog_editSession = (details) => {
    setSessionDetails(details);
    setIsEdit(!isEdit);
  };

  const tog_attachDoc = (id) => {
    setSessionID(id);
    setIsDocAttach(!isDocAttach);
  };

  const tog_assignUser = (id) => {
    setUserAssign(!isUserAssign);
    setSessionID(id);
  };

  const tog_deleteSession = (id) => {
    setSessionID(id);
    setIsToggleDelete(!isToggleDelete);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Session Name</span>,
      selector: (row) => row?.sessionName,
    },
    {
      name: <span className="font-weight-bold fs-13">Session Type</span>,
      cell: (row) => {
        if (row?.sessionFormat) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              {row?.sessionFormat}
              <div></div>
            </div>
          );
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Speakers</span>,
      cell: (row) => {
        return (
          <>
            {row?.speakers?.map((speaker, i) => {
              return (
                <div
                  key={i}
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "40px",
                  }}
                >
                  <img
                    src={speaker?.photo}
                    className="w-100 h-100"
                    style={{ borderRadius: "30px" }}
                  />
                </div>
              );
            })}
          </>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {row?.status}
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Start Time</span>,
      selector: (row) => row?.startTime,
    },
    // {
    //   name: <span className="font-weight-bold fs-13">End Time</span>,
    //   selector: (row) => row?.endTime,
    // },
    {
      name: <span className="font-weight-bold fs-13">Date</span>,
      selector: (row) => row?.sessionDate,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip text="View Session details">
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => tog_viewSession(row)}
              />
            </Tooltip>

            <Tooltip text="Edit Session">
              <FaEdit
                size={20}
                className="mx-1"
                onClick={() => tog_editSession(row)}
              />
            </Tooltip>
            <Tooltip text="Attachment Document">
              <CgAttachment
                size={18}
                className="mx-1"
                onClick={() => tog_attachDoc(row?._id)}
              />
            </Tooltip>
            <Tooltip text="Add User">
              <BsPersonFillAdd
                size={20}
                className="mx-1"
                onClick={() => tog_assignUser(row?._id)}
              />
            </Tooltip>
            <div>
              <AiFillDelete
                size={20}
                className="mx-1"
                onClick={() => tog_deleteSession(row?._id)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  // console.log("getMySessions....", JSON.stringify(getMySessions, null, 2));

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  if (getMySessions?.data?.length === 0 && getMySessions?.status === "ok") {
    return (
      <div className="w-100 h-100 mt-5">
        <Nothing title="No Session at the moment" />
        <div className="w-100 d-flex justify-content-center">
          <ButtonIcon
            Icon={<FaPlus size={20} color="white" />}
            Title="Add Session"
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={10}
            handleOnclick={() => setIsAddSession(!isAddSession)}
            pending={false}
          />
        </div>
        <OffCanvas
          isOffcanvasOpen={isAddSession}
          title="Add Session"
          body={
            <AddSession
              userID={userID}
              token={token}
              eventID={eventDetails?._id}
              eventFormat={eventDetails?.eventFormat}
              setIsOffcanvasOpen={setIsAddSession}
            />
          }
          toggleSaveChanges={() => setIsAddSession(!isAddSession)}
          directionOffCanvas="end"
          widthOffCanvas={500}
        />
      </div>
    );
  }

  // Sorting sessions by sessionDate
  const sortedSessions = [...(getMySessions?.data || [])].sort((a, b) =>
    a.sessionDate.localeCompare(b.sessionDate)
  );

  // console.log("Sessions.....", sortedSessions);

  const filterSessions = (session, searchQuery) => {
    if (!searchQuery) return session;
    return session?.filter((item) =>
      [item?.sessionName, item?.sessionFormat].some((field) =>
        field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };

  const filteredSession = filterSessions(sortedSessions, search);

  return (
    <div className="w-100 p-3">
      <div style={{ fontSize: 18, fontWeight: "600" }}>Sessions</div>
      <div className="w-100 mb-3">
        Add and Manage all Sessions and invite speakers to your different
        sessions. Your invited speakers will receive session invitation via
        their email.
      </div>
      <div className="w-100 d-flex justify-content-between">
        <SearchInput
          Title="Search"
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
          radius={25}
        />
        <div className="d-flex justify-content-center align-items-center">
          <ButtonIcon
            Title="Add Session"
            Icon={<FaPlus size={14} color="white" />}
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={30}
            handleOnclick={() => setIsAddSession(!isAddSession)}
            pending={false}
          />
        </div>
      </div>
      <div className="w-100 mt-3">
        <div className="analystic-table-card">
          <div className="w-100">
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="300px"
              columns={columns}
              responsive
              data={filteredSession}
              pagination
            />
          </div>
        </div>
      </div>
      <OffCanvas
        isOffcanvasOpen={isUserAssign}
        title="Assign Host/ Speaker"
        body={
          <AssignUser
            userID={userID}
            token={token}
            eventID={eventDetails?._id}
            eventDetails={eventDetails}
            sessionID={sessionID}
            eventFormat={eventDetails?.eventFormat}
            setIsOffcanvasOpen={setUserAssign}
          />
        }
        toggleSaveChanges={() => setUserAssign(!isUserAssign)}
        directionOffCanvas="end"
        widthOffCanvas={500}
      />
      <OffCanvas
        isOffcanvasOpen={isAddSession}
        title="Add Session"
        body={
          <AddSession
            userID={userID}
            token={token}
            eventID={eventDetails?._id}
            eventFormat={eventDetails?.eventFormat}
            setIsOffcanvasOpen={setIsAddSession}
          />
        }
        toggleSaveChanges={() => setIsAddSession(!isAddSession)}
        directionOffCanvas="end"
        widthOffCanvas={500}
      />
      <OffCanvas
        isOffcanvasOpen={isViewSession}
        title="Session Details"
        body={<ViewSession sessionDetails={sessionDetails} />}
        toggleSaveChanges={() => setIsViewSession(!isViewSession)}
        directionOffCanvas="end"
        widthOffCanvas={500}
      />
      <OffCanvas
        isOffcanvasOpen={isEdit}
        title="Edit Session"
        body={
          <EditSession
            sessionDetails={sessionDetails}
            eventID={eventDetails?._id}
            token={token}
            setIsOffcanvasOpen={setIsEdit}
          />
        }
        toggleSaveChanges={() => setIsEdit(!isEdit)}
        directionOffCanvas="end"
        widthOffCanvas={500}
      />

      <OffCanvas
        isOffcanvasOpen={isDocAttach}
        title="Session Document"
        body={
          <AttachSessionDocument
            token={token}
            eventID={eventDetails?._id}
            sessionID={sessionID}
            setShowAttachDocument={setIsDocAttach}
          />
        }
        toggleSaveChanges={() => setIsDocAttach(!isDocAttach)}
        directionOffCanvas="end"
        widthOffCanvas={500}
      />
      <ModalDelete
        size="md"
        isOpen={isToggleDelete}
        toggle={() => {
          setIsToggleDelete(false);
        }}
        title="Delete Session"
        body={
          <DeleteForm
            setIsToggleDelete={setIsToggleDelete}
            token={token}
            eventID={eventDetails?._id}
            sessionID={sessionID}
          />
        }
      />
    </div>
  );
};

export default Sessions;
