import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useCustomFecthQuery from "../../Hooks/useCustomFecthQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import LobbyHeader from "../../Layout/HorizontalLayout/LobbyHeader";
import boothbgImg from "../../assets/images/background/booth-bg.png";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import { FaRegCalendarAlt } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import BoothLobbyNav from "../../components/BoothLobby/Exhibitor/BoothLobbyNav";
import boothOne from "../../assets/images/booths/1.png";
import boothTwo from "../../assets/images/booths/2.png";
import boothThree from "../../assets/images/booths/3.png";
import boothFour from "../../assets/images/booths/4.png";

const boothDetails = {
  _id: "674b5abcde84d17801cdadaf",
  object: "booths",
  boothNumber: "#001",
  boothImage: "4",
  boothLogo:
    "https://storage.googleapis.com/event-kago-nodejs.appspot.com/Images/boothLogo/boothLogo-67457f9d0d2a8637497729c8-1732991674784.jpeg?GoogleAccessId=firebase-adminsdk-pn0ly%40event-kago-nodejs.iam.gserviceaccount.com&Expires=1898553600&Signature=kQzLa3%2BzZtnb%2Flv5rnu3sicuNZhUjTIrzQU3fmNUo%2B9dhy33Wss6zWAgX6184%2FRtV6qz4gJeDZRv0An%2Fn%2BQttac%2FtCYkjbRN5cz6F2cZWVCDHsLupeqZw64Y38Lm9YjU7Vi90f0sJB9nnV9WajQQfl5jRv4tSyJp5v379P5A7wsRnKHRavYHIhpPKYdKuSAiannGRLRfwymjxzZXwQ%2FCsJzZ0ZtGB7lCo50Ct35bT0KA3KwhlRedboVb5kH2VDo8%2F%2FMFy%2BeBOAn02FeYLWGqyr8Gn%2BU82Bi%2BdyOTRurca0rucMnAWtXUNxIzicaM7UteZKsaHHBRqCy6PbO3GSatUw%3D%3D",
  boothName: "TNG Stand",
  boothTags: ["Tech,Innovation"],
  boothExhibitor: "jed@tngsolutions.co.za",
  organization: "TNG Solutions",
  chat: true,
  duration: 40,
  boothDescription: "This is the description of the booth ",
  additionalMaterial: [],
  eventId: "673dd8ee7544140061c60da2",
  ratings: [],
  companyProfiles: [],
  __v: 0,
  id: "674b5abcde84d17801cdadaf",
};
const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "none",
          // padding: "6px 20px", // Adjusted padding
          borderRadius: "5px",
          minWidth: "unset", // Removes default min-width for the tabs to not take up full width
          "&.Mui-selected": {
            backgroundColor: "#F68A20", // Active tab background
            color: "#fff", // Active tab text color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          },
          "&:not(.Mui-selected)": {
            // backgroundColor: "#f2f2f2", // Inactive tab background
            color: "#000", // Inactive tab text color
          },
        },
      },
    },
  },
  palette: {
    primary: {
      // main: "#F68A20",
      main: "#000",
    },
    secondary: {
      main: "#ABABAB",
    },
  },
});

const BoothLobby = () => {
  const params = useParams();
  const eventName = params.eventName;
  const boothName = params.boothName;
  const boothID = params.boothID;
  const [value, setValue] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //  const {
  //    data: getBoothDetails,
  //    error: errorGetBoothDetails,
  //    isLoading: isLoadingGetBoothDetails,
  //  } = useCustomFecthQuery(
  //    ["boothDetails", boothID],
  //    `${process.env.REACT_APP_API}booths/?email=${userEmail}&eventId=${eventID}`,
  //    token,
  //    { enabled: !!boothID }
  //  );

  console.log("eventName...", eventName);
  console.log("boothName...", boothName);
  console.log("boothID...", boothID);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <div
          className=""
          style={{
            height: "100vh",
            width: "100%",
          }}
        >
          <LobbyHeader />
          <BoothLobbyNav
            eventName={eventName}
            boothName={boothName}
            boothID={boothID}
          />

          <div
            style={{
              height: "100vh",
              width: "100%",
              backgroundImage: `url(${boothbgImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              className="w-100"
              style={{
                backgroundImage: `url(${boothbgImg})`,
                backgroundSize: "cover",
                paddingTop: "2%",
              }}
            >
              <div className="w-100 d-flex justify-content-center">
                <div style={{ width: 400, height: "100%" }}>
                  <img
                    src={
                      boothDetails?.boothImage === "1"
                        ? boothOne
                        : boothDetails?.boothImage === "2"
                        ? boothTwo
                        : boothDetails?.boothImage === "3"
                        ? boothThree
                        : boothDetails?.boothImage === "4"
                        ? boothFour
                        : null
                    }
                    alt="booth-image"
                    className="w-100 h-100"
                  />
                </div>
              </div>

              <div className="w-100 d-flex justify-content-center">
                <Box
                  sx={{ width: "80%", marginTop: "-4%", marginBottom: "4%" }}
                >
                  <Box
                    // sx={{ width: "80%", marginTop: "-4%", marginBottom: "4%" }}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#ABABAB",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      textColor="primary"
                      indicatorColor="secondary"
                      aria-label="navigation tabs"
                      variant="scrollable"
                      scrollButtons="auto"
                      allowScrollButtonsMobile
                    >
                      <Tab label="Description" />
                      <Tab label="Company profile" />
                      <Tab label="Documents" />
                      <Tab label="Chats" />
                      <Tab label="Enquiry" />
                    </Tabs>
                  </Box>

                  {/* Tab content area */}
                  <Box
                    sx={{
                      padding: 2,
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                      background: "white",
                    }}
                  >
                    {value === 0 && (
                      <Box>
                        <Typography variant="body1" sx={{ marginTop: "16px" }}>
                          {boothDetails?.boothDescription}
                        </Typography>
                      </Box>
                    )}
                    {value === 1 && (
                      <Typography>Company Profile Tab Content</Typography>
                    )}
                    {value === 2 && (
                      <Typography>Documents Tab Content</Typography>
                    )}
                    {value === 3 && <Typography>Chats Tab Content</Typography>}
                    {value === 4 && <Typography>Equiry Tab Content</Typography>}
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default BoothLobby;
