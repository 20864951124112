import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonIcon from "../../Buttons/ButtonIcon";
import { FaRegCalendarAlt } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import { LiaStoreAltSolid } from "react-icons/lia";

const BoothNaviagtion = ({ eventName, eventID, boothID }) => {
  const navigate = useNavigate();
  return (
    <div className="w-100 py-2 d-flex justify-content-between">
      <div>
        <ButtonIcon
          Title="Back"
          BackgroundColor="white"
          ColorText="black"
          BorderColor="white"
          borderRadius={20}
          handleOnclick={() => navigate(-1)}
          Icon={<BiArrowBack color="black" size={20} />}
          pending={false}
        />
      </div>
      <div>
        <ButtonIcon
          Title="View Event page"
          BackgroundColor="#0ac074"
          ColorText="white"
          BorderColor="#0ac074"
          borderRadius={20}
          handleOnclick={() =>
            navigate(`/${eventName}/event-landing/${eventID}`)
          }
          Icon={<FaRegCalendarAlt color="white" size={20} />}
          pending={false}
        />
      </div>
      {boothID && (
        <div>
          <ButtonIcon
            Title="Preview Booth"
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={() => {}}
            Icon={<LiaStoreAltSolid color="white" size={20} />}
            pending={false}
          />
        </div>
      )}
    </div>
  );
};

export default BoothNaviagtion;
