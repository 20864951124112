import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import usePost from "../../Hooks/usePost";
import { decryptDataInOneLine } from "../../utils/decryptDataInOneLine";
import SentMail from "../../components/Loader/SentMail";

function PayConfirmation() {
  const location = useLocation();
  const Navigate = useNavigate();
  const { status, execute, data, pending } = usePost();
  const queryParams = new URLSearchParams(location.search);
  const [userInfo, setUserInfo] = useState(null);

  const handleAssignPackage = () => {
    const Method = "PATCH",
      url = `packages/assign/${userInfo?.packageId}`,
      isJSON = true;
    const raw = null;
    execute(url, raw, Method, true, null, isJSON);
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: null,
      token: null,
      isJSON: isJSON,
    };
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const encryptedData = queryParams.get("data");

    if (encryptedData) {
      const secretKey = "14102024@Kago";
      const decryptedData = decryptDataInOneLine(encryptedData, secretKey);

      // Assuming the decrypted data is a query string, you can parse it to get the user info
      const userData = new URLSearchParams(decryptedData);

      setUserInfo({
        firstName: userData.get("name_first"),
        lastName: userData.get("name_last"),
        email: userData.get("email"),
        phone: userData.get("phone"),
        packageId: userData.get("packageId"),
      });
      // handleAssignPackage();
    }
    if (status === "success") {
      Navigate("/message/inbox");
    }
  }, [location.search, status]);

  return (
    <div className="w-100 h-100">
      {/* <ValidationMessage
        handleAssignPackage={handleAssignPackage}
        status={pending}
      /> */}
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="successFullMessage-container d-flex justify-content-center align-items-center">
          <div
            style={{
              width: 500,
              backgroundColor: "white",
              padding: 10,
              borderRadius: 10,
            }}
          >
            <div className="mt-4">
              <div
                className="w-100 d-flex justify-content-center"
                style={{
                  userSelect: "none",
                  color: "#f68a20",
                  fontSize: 18,
                  fontWeight: "800",
                }}
              >
                To complete your registration
              </div>
              <div className="w-100 d-flex justify-content-center ">
                <SentMail title="Please click below" />
              </div>
            </div>
            <div className="mt-3 d-flex justify-content-center">
              <buton
                className="btn"
                style={{
                  fontWeight: "600",
                  color: "white",
                  borderColor: "#f68a20",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderRadius: 15,
                  backgroundColor: "#f68a20",
                }}
                onClick={() => handleAssignPackage()}
              >
                {pending ? "Loading..." : "OK"}
              </buton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayConfirmation;
