import React from "react";
import NotificationPayment from "./component/NotificationPayment";
import WithdrawalCard from "./component/WithdrawalCard";
import PaymentDetails from "./component/PaymentDetails";
import GeneralNotice from "./component/GeneralNotice";

const Withdrawal = ({ userID, token, eventID }) => {
  return (
    <React.Fragment>
      <div className="w-100 mt-1 p-4" style={{ paddingTop: "5%" }}>
        <NotificationPayment />
      </div>
      <div className="w-100 px-4">
        <WithdrawalCard userID={userID} token={token} eventID={eventID} />
      </div>
      <div className="w-100 p-4">
        <PaymentDetails userID={userID} token={token} eventID={eventID} />
      </div>
      <div className="w-100 px-4">
        <GeneralNotice userID={userID} token={token} eventID={eventID} />
      </div>
    </React.Fragment>
  );
};
export default Withdrawal;
