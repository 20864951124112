import React from "react";
import ImgLogo from "../../assets/images/Kago-color-logo.png";

const PayNowHeader = () => {
  return (
    <React.Fragment>
      <div
        className="w-100 d-flex justify-content-between align-items-center px-4"
        style={{ height: 65, backgroundColor: "white", userSelect: "none" }}
      >
        <div className="" style={{ height: 45, objectFit: "contain" }}>
          <img className="w-100 h-100" src={ImgLogo} alt="Company Logo" />
        </div>
      </div>
    </React.Fragment>
  );
};
export default PayNowHeader;
