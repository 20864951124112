import React, { useState, useEffect, useRef } from "react";
import Lottie from "lottie-react";
import live from "../../../../assets/animations/live.json";
import { eventCountDown } from "../../../../utils/eventCountDown";

import Divider from "./Divider";
import ButtonGradient from "../../../Buttons/ButtonGradient";
const About = ({ eventDetails }) => {
  const liveRef = useRef();
  const [countDown, setCountDown] = useState({ status: null, count: null });

  useEffect(() => {
    if (eventDetails) {
      const intervalId = setInterval(() => {
        const count = eventCountDown(
          eventDetails?.startDate,
          eventDetails?.endDate
        );
        setCountDown(count);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [eventDetails]);

  return (
    <React.Fragment>
      <div className="w-100 pt-3" style={{ backgroundColor: "white" }}>
        <h1
          id="about"
          className="d-flex justify-content-center"
          style={{ color: "black", fontWeight: "bold" }}
        >
          About Event
        </h1>
        <Divider />
        <div className="w-100 mt-4 d-flex justify-content-center">
          <p style={{ width: "67%", textAlign: "center" }}>
            {eventDetails?.eventDescription}
          </p>
        </div>
        {countDown?.status === "Completed" ? (
          <>
            <div className="w-100 d-flex justify-content-center p-2">
              <ButtonGradient
                Title="Event Completed"
                leftColor="#f7983c"
                rightColor="#ff7c6e"
                ColorText="white"
                BorderColor="transparent"
                borderRadius={50}
                handleOnclick={() => {}}
              />
            </div>
          </>
        ) : countDown?.status === "Ongoing" ? (
          <>
            <div
              className="w-100 mt-1 d-flex justify-content-center"
              style={{ width: 60, height: 40 }}
            >
              <Lottie lottieRef={liveRef} animationData={live} />
            </div>
          </>
        ) : countDown?.status === "UpComing" ? (
          <>
            <div className="w-100 mt-2 d-flex justify-content-center">
              <h4>Event Will Start In</h4>
            </div>
            <div className="count-down-box">
              <div className="mt-2 count-down-box-container">
                <div className="mx-2 count-down-box-wrapper">
                  <h1 className="count-down-box-title">
                    {countDown?.data?.days}
                  </h1>
                  <div className="count-down-box-time">DAYS</div>
                </div>
                <div className="mx-2 count-down-box-wrapper">
                  <h1 className="count-down-box-title">
                    {countDown?.data?.hours}
                  </h1>
                  <div className="count-down-box-time">HOURS</div>
                </div>
                <div className="mx-2 count-down-box-wrapper">
                  <h1 className="count-down-box-title">
                    {countDown?.data?.minutes}
                  </h1>
                  <div className="count-down-box-time">MINUTES</div>
                </div>
                <div className="mx-2 count-down-box-wrapper">
                  <h1 className="count-down-box-title">
                    {countDown?.data?.seconds}
                  </h1>
                  <div className="count-down-box-time">SECONDS</div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default About;
