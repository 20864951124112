import React, { useState } from "react";
import { Search, Calendar, MapPin, Filter } from "lucide-react";
import { CardBody } from "reactstrap"; // Import Card from reactstrap
import "./eventFilter.scss"; // Import the custom CSS file

const EventFilter = () => {
  const [eventType, setEventType] = useState("All Types");
  const [isFreeEvent, setIsFreeEvent] = useState("All");

  return (
    <CardBody className="filter-sidebar-container">
      <div className="filter-sidebar-space-y-6">
        <div className="filter-sidebar-header">
          <h2>
            <Filter className="w-5 h-5" />
            Filter Events
          </h2>
        </div>

        <div className="filter-sidebar-space-y-4">
          {/* Event Name */}
          <div className="filter-sidebar-space-y-2">
            <label className="text-sm font-medium text-gray-700">
              Event Name
            </label>
            <div className="relative">
              {/* <Search className="filter-sidebar-input-icon" /> */}
              <input
                type="text"
                placeholder="Search event name"
                className="filter-sidebar-input"
              />
              {/* <span>Hi</span> */}
            </div>
          </div>

          {/* Location */}
          <div className="filter-sidebar-space-y-2 mt-2">
            <label className="text-sm font-medium text-gray-700">
              Location
            </label>
            <div className="relative">
              <input
                type="text"
                placeholder="Search location"
                className="filter-sidebar-input"
              />
              {/* <MapPin className="filter-sidebar-input-icon" /> */}
            </div>
          </div>

          {/* Date Range */}
          <div className="filter-sidebar-space-y-2">
            {/* <label className="text-sm font-medium text-gray-700">Date</label> */}
            <div className="">
              <label className="text-sm font-medium text-gray-700">From</label>
              <div className="relative flex-1">
                <input type="date" className="filter-sidebar-input" />
                {/* <Calendar className="filter-sidebar-input-icon" /> */}
              </div>
              <label className="text-sm font-medium text-gray-700 mt-2">
                To
              </label>
              <div className="relative flex-1">
                <input type="date" className="filter-sidebar-input" />
                {/* <Calendar className="filter-sidebar-input-icon" /> */}
              </div>
            </div>
          </div>

          {/* Event Type */}
          <div className="filter-sidebar-space-y-2 mt-2">
            <label className="text-sm font-medium text-gray-700">
              Event Type
            </label>
            <select
              value={eventType}
              onChange={(e) => setEventType(e.target.value)}
              className="filter-sidebar-select"
            >
              <option>All Types</option>
              <option>Conference</option>
              <option>Workshop</option>
              <option>Seminar</option>
              <option>Webinar</option>
            </select>
          </div>

          {/* Payment Type */}
          <div className="filter-sidebar-space-y-2 mt-2">
            <label className="text-sm font-medium text-gray-700">Pricing</label>
            <select
              value={isFreeEvent}
              onChange={(e) => setIsFreeEvent(e.target.value)}
              className="filter-sidebar-select"
            >
              <option>All</option>
              <option>Free</option>
              <option>Paid</option>
            </select>
          </div>

          {/* Apply Button */}
          <button className="filter-sidebar-button mt-2">Apply Filters</button>
        </div>
      </div>
    </CardBody>
  );
};

export default EventFilter;
