import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { useParams } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Tabs, Tab, Box } from "@mui/material";
import LobbyHeader from "../../../Layout/HorizontalLayout/LobbyHeader";
import Footer from "../../../Layout/HorizontalLayout/Footer";
import { detectDeviceType } from "../../../utils/deviceType";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import About from "./One/About";
import Schedule from "./One/Schedule";
import ButtonSubmit from "../../Buttons/ButtonSubmit";

import Reception from "./ExhibitionComponents/Reception";
import Booths from "./ExhibitionComponents/Booths";
import ExhibitorBooth from "./ExhibitionComponents/ExhibitorBooth";

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "none",
          "&.Mui-selected": {
            color: "#F68A20",
          },
          "&:not(.Mui-selected)": {
            color: "black",
          },
        },
      },
    },
  },
  palette: {
    secondary: {
      main: "#F68A20",
    },
  },
});

const formatLabel = (label) =>
  `${label.charAt(0).toUpperCase()}${label.slice(1).toLowerCase()}`;

const EventExhibitionOne = ({ eventName }) => {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userEmail = obj?.data?.data?.email;
  const userRole = obj?.data?.data?.roles;
  const token = obj?.data?.token;
  const params = useParams();
  const eventID = params.eventID;
  const deviceType = detectDeviceType();
  const [selectedTab, setSelectedTab] = useState("1");

  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomFecthQuery(
    ["myEventDetails", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomFecthQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  if (isLoadingGetMyEventDetail) {
    return <>Loading....</>;
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const checkForRole = (personnelArray, targetEmail) => {
    const roles = {
      isOrganizer: false,
      isCoOrganizer: false,
      isHost: false,
      isCoordinator: false,
      isExhibitor: false,
      isExhibitorAssistance: false,
      isSpeaker: false,
    };

    personnelArray?.forEach((person) => {
      if (person?.email === targetEmail) {
        Object?.keys(roles)?.forEach((role) => {
          if (person?.role?.includes(role?.slice(2)?.toLowerCase())) {
            roles[role] = true;
          }
        });
      }
    });
    return roles;
  };

  const {
    isOrganizer,
    isCoOrganizer,
    isCoordinator,
    isExhibitor,
    isExhibitorAssistance,
    isSpeaker,
    isHost,
  } = checkForRole(getMyEventDetail?.data?.personnel, userEmail);
  const isAttendee = getMyEventDetail?.data?.attendees?.includes(userEmail);

  return (
    <React.Fragment>
      <div
        className="w-100"
        // style={{ height: "92vh" }}
      >
        <ThemeProvider theme={theme}>
          <LobbyHeader />
          <Box sx={{ backgroundColor: "#282e3f", mt: 0, p: 4 }} />
          <Box sx={{ backgroundColor: "white", width: "100%" }}>
            <div
              className="w-100 d-flex justify-content-center pt-3"
              style={{
                position: "fixed",
                backgroundColor: "white",
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="community navigation tabs"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab value="1" label={formatLabel("Reception")} />
                {isExhibitor || isExhibitorAssistance ? (
                  <Tab value="2" label={formatLabel("My Booth")} />
                ) : null}
                {isAttendee || isOrganizer || isCoOrganizer || isHost ? (
                  <Tab value="2" label={formatLabel("Booths")} />
                ) : null}
                {/* <Tab value="3" label={formatLabel("Sessions")} /> */}

                {/* <Tab value="4" label={formatLabel("Community Chat")} /> */}
              </Tabs>
            </div>
          </Box>
          {selectedTab === "1" && (
            <Reception
              eventDetails={getMyEventDetail?.data}
              setSelectedTab={setSelectedTab}
              userEmail={userEmail}
              userRole={userRole}
              token={token}
              isExhibitor={isExhibitor || isExhibitorAssistance}
            />
          )}
          {isAttendee || isOrganizer || isCoOrganizer || isHost ? (
            <>
              {selectedTab === "2" && (
                <Booths eventName={eventName} eventID={eventID} token={token} />
              )}
            </>
          ) : null}
          {isExhibitor || isExhibitorAssistance ? (
            <>
              {selectedTab === "2" && (
                <ExhibitorBooth
                  userEmail={userEmail}
                  eventName={eventName}
                  eventID={eventID}
                  token={token}
                />
              )}
            </>
          ) : null}

          {/* <Footer /> */}
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
};

export default EventExhibitionOne;
