import React from "react";
import backgroundImg from "../../../../assets/images/background/background.png";
import "./.receptionLobby.scss";

const Reception = ({ isExhibitor, setSelectedTab }) => {
  // setSelectedTab;
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "84vh",
        width: "100%",
        marginTop: "4%",
      }}
    >
      <div className="lobby-booth-reception">
        <button
          className="btn-exhibition-lobby"
          onClick={() => setSelectedTab("2")}
        >
          {isExhibitor ? "My Booth" : "Booths"}
        </button>
        {/* <button
          className="btn-exhibition-lobby"
          onClick={() => setSelectedTab("3")}
        >
         Sessions
        </button> */}
        <button
          className="btn-exhibition-lobby"
          onClick={() => setSelectedTab("4")}
        >
          Community
        </button>
      </div>
    </div>
  );
};

export default Reception;
