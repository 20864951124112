import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
// import ProfilePicture from "../../";
import ButtonSubmit from "../../Buttons/ButtonSubmit";

function AddSession({
  eventID,
  eventFormat,
  userId,
  token,
  setIsOffcanvasOpen,
}) {
  // const [uploadImg, setUploadImg] = useState(null);
  const [inputs, setInputs] = useState({});
  const key = ["mySessions", eventID];
  const mutation = useCustomMutation(key);
  // console.log("eventFormat", eventFormat);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const toggleSaveChanges = (e) => {
    e.preventDefault();
    const Method = "POST",
      url = `sessions/${eventID}`,
      isJSON = null;
    var raw = JSON.stringify({
      sessionName: inputs.sessionName,
      sessionLocation: inputs.sessionLocation ? inputs.sessionLocation : null,
      sessionFormat: eventFormat,
      sessionDate: inputs.sessionDate,
      startTime: inputs.startTime,
      duration: inputs.duration,
    });
    // formdata.append();
    // if (eventFormat !== "Virtual") {
    // formdata.append(");}

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Session successfully created",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };
  // maximum duration 480
  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
    }
  }, [mutation?.isSuccess]);

  return (
    <div className="w-100 d-flex justify-content-center p-3">
      <div style={{ width: 400 }}>
        <form
          noValidate
          autoComplete="off"
          data-testid="form"
          onSubmit={toggleSaveChanges}
        >
          <Row>
            <Col md={12}>
              <label>
                Session Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="sessionName"
                value={inputs?.sessionName}
                placeholder="Enter Session Name"
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            {eventFormat === "Virtual" ? null : (
              <Col md={12}>
                <label>
                  Location <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="sessionLocation"
                  value={inputs?.sessionLocation}
                  placeholder="Enter Session Location"
                  onChange={handleChange}
                  style={{ borderRadius: 7 }}
                  className="mb-3 form-control fromInput"
                  required
                />
              </Col>
            )}

            <Col md={12}>
              <label>
                Session Date <span className="text-danger">*</span>
              </label>
              <input
                type="date"
                name="sessionDate"
                value={inputs?.sessionDate}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>

            <Col md={6}>
              <label>
                Start Time <span className="text-danger">*</span>
              </label>
              <input
                type="time"
                name="startTime"
                value={inputs?.startTime}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            <Col md={6}>
              <label>
                Duration (minutes) <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                name="duration"
                value={inputs?.duration}
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            <div className="mb-3"></div>
            <div className="w-100 d-flex justify-content-center">
              <ButtonSubmit
                Title="Create"
                ColorText="white"
                BorderColor="#F68A20"
                BackgroundColor="#F68A20"
                borderRadius="25px"
                handleOnclick={toggleSaveChanges}
                pending={mutation?.isLoading}
              />
            </div>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default AddSession;
