import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import useCustomMutation from "../../../Hooks/useCustomMutation";

import boothOne from "../../../assets/images/booths/1.png";
import boothTwo from "../../../assets/images/booths/2.png";
import boothThree from "../../../assets/images/booths/3.png";
import boothFour from "../../../assets/images/booths/4.png";
import SwitchButton from "../../Buttons/SwitchButton";
import ButtonSubmit from "../../Buttons/ButtonSubmit";
import { IoImageOutline } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";

import "./boothForm.scss";

const BoothForm = ({
  eventID,
  boothSpaceId,
  boothLiveBroadcast,
  duration,
  userEmail,
  token,
  setIsBoothOpen,
}) => {
  const [boothLogo, setBoothLogo] = useState(null);
  const [inputs, setInputs] = useState({
    boothName: "",
    boothTags: [],
    boothExhibitor: "",
    organization: "",
    chat: false,
    liveBroadcast: false,
    boothDescription: "",
    boothImage: 1,
  });
  const key = ["myBooth", userEmail];
  const mutation = useCustomMutation(key);
  const [boothLogoImg, setBoothLogoImg] = useState(null);
  const [selectedBoothImage, setSelectedBoothImage] = useState(null);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const fileInputRef = useRef(null);

  const handleImageUpload = (e) => {
    const file = e.target?.files[0];

    if (file) {
      setBoothLogo(file);

      const reader = new FileReader();
      reader.onload = (e) => setBoothLogoImg(e.target.result);

      reader.readAsDataURL(file);
    }
  };

  const selectBoothFunc = (imageSrc, index) => {
    setInputs({ ...inputs, boothImage: index + 1 });
    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "boothImage.png", {
          type: "image/png",
        });
        setSelectedBoothImage(imageSrc);
      });
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const submitFunc = (e) => {
    e.preventDefault();
    const Method = "POST",
      url = `booths/${eventID}`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("boothLogo", boothLogo);
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Booth Successfully Set",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (boothSpaceId) {
      setInputs({
        ...inputs,
        liveBroadcast: boothLiveBroadcast,
        duration: duration,
        boothExhibitor: userEmail,
      });
    }
    if (mutation?.isSuccess) {
      setIsBoothOpen(false);
    }
  }, [boothSpaceId, mutation?.isSuccess]);

  const handleKeyDown = (e) => {
    if (e?.key === "Enter" && tagInput?.trim()) {
      setTags([...tags, tagInput.trim()]);
      setInputs({
        ...inputs,
        boothTags: [...tags, tagInput?.trim()],
      });
      setTagInput("");
    } else if (e.key === "Backspace" && !tagInput && tags.length > 0) {
      setTags(tags.slice(0, -1));
      setInputs({
        ...inputs,
        boothTags: tags.slice(0, -1),
      });
    }
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
    setInputs({
      ...inputs,
      boothTags: tags.filter((_, index) => index !== indexToRemove),
    });
  };

  const style = {
    container: {
      border: "1px solid #ddd",
      padding: "8px",
      borderRadius: "4px",
      display: "flex",
      flexWrap: "wrap",
      gap: "8px",
      minHeight: "44px",
    },
    tagContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "8px",
    },
    tag: {
      backgroundColor: "#f68a20",
      color: "#fff",
      borderRadius: "16px",
      padding: "4px 12px",
      display: "flex",
      alignItems: "center",
      gap: "6px",
    },
    deleteButton: {
      background: "none",
      border: "none",
      color: "#666",
      cursor: "pointer",
      padding: "0",
      fontSize: "14px",
    },
    input: {
      border: "none",
      outline: "none",
      padding: "4px",
      fontSize: "14px",
      flex: "1 0 120px",
      minWidth: "120px",
    },
  };

  console.log("eventID..", eventID);
  console.log("boothLogo..", JSON.stringify(inputs, null, 2));

  return (
    <div className="w-100">
      <form
        autoComplete="off"
        data-testid="form"
        enctype="multipart/form-data"
        onSubmit={submitFunc}
      >
        <div className="w-100 d-flex justify-content-center">
          Logo size: 300x50px
        </div>

        <div className="w-100 d-flex justify-content-center">
          <div style={{ width: 170, height: 60 }}>
            {boothLogoImg ? (
              <img
                src={boothLogoImg}
                alt="booth-logo"
                className="w-100 h-100 border border-primary"
                style={{ borderRadius: 5, objectFit: "cover" }}
              />
            ) : (
              <div
                className="w-100 h-100 border border-primary"
                style={{ borderRadius: 5 }}
              ></div>
            )}
          </div>
        </div>
        <div className="upload-booth-exhibibtion-logo-btn-container mb-3">
          <div className="upload-booth-exhibibtion-logo-btn">
            <button
              type="button"
              onClick={() => fileInputRef?.current?.click()}
              className="upload-booth-exhibibtion-logo-item"
            >
              <IoImageOutline size={16} color="#f68a20" />
              Upload Booth logo
            </button>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </div>
        </div>

        <div className="w-100 d-flex justify-content-center">
          <Row>
            <Col md={12}>
              <div className="w-100 mt-4 mb-2 text-dark h5 mx-3">
                Select Booth Image :
              </div>
              <div className="w-100 d-flex justify-content-center images-container mb-4">
                {[boothOne, boothTwo, boothThree, boothFour].map(
                  (standImage, index) => (
                    <img
                      key={index}
                      src={standImage}
                      alt={`Booth ${index + 1}`}
                      onClick={() => selectBoothFunc(standImage, index)}
                      style={{
                        width: "120px",
                        height: "120px",

                        cursor: "pointer",
                        border:
                          selectedBoothImage === standImage
                            ? "2px solid #f68a20"
                            : "none",
                        borderRadius: "8px",
                        marginRight: "10px",
                        backgroundColor: "#f2f2f2",
                      }}
                    />
                  )
                )}
              </div>
            </Col>

            <Col md={6} lg={6}>
              <label>
                <span className="text-danger">*</span>Booth name
              </label>
              <input
                type="text"
                name="boothName"
                value={inputs?.boothName}
                // placeholder="Enter Last name"
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control"
                required
              />
            </Col>
            <Col md={6} lg={6}>
              <label>
                <span className="text-danger">*</span>Organization
              </label>
              <input
                type="text"
                name="organization"
                value={inputs?.organization}
                // placeholder="Enter Last name"
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control "
                required
              />
            </Col>

            <Col md={12}>
              <label>
                <span className="text-danger">*</span>Booth Tags
              </label>
              <div style={style.container}>
                <div style={style.tagContainer}>
                  {tags.map((tag, index) => (
                    <span key={index} style={style.tag}>
                      {tag}
                      <div
                        onClick={() => removeTag(index)}
                        style={style.deleteButton}
                      >
                        <MdOutlineCancel size={16} color="white" />
                      </div>
                    </span>
                  ))}
                </div>
                <input
                  type="text"
                  value={tagInput}
                  onChange={(e) => setTagInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                      handleKeyDown(e); // Handle the tag addition
                    }
                  }}
                  placeholder="Add tags..."
                  style={style.input}
                />
              </div>
            </Col>
            <Col md={12}>
              <label>
                <span className="text-danger">*</span>Booth Description
              </label>
              <textarea
                name="boothDescription"
                value={inputs?.boothDescription}
                onChange={handleChange}
                placeholder="Describe here..."
                style={{ width: 500, borderRadius: 7 }}
                className="w-100 mb-3 mt-1 form-control"
                rows={5}
                required
              ></textarea>
            </Col>

            <Col md={6}>
              <div className="w-100 d-flex align-items-center gap-2">
                <SwitchButton
                  isOn={inputs?.chat}
                  handleToggle={() =>
                    setInputs({ ...inputs, chat: !inputs?.chat })
                  }
                />
                <div className="d-flex text-primary fw-700">Set Live Chat</div>
              </div>
            </Col>
            <Col md={6}></Col>
          </Row>
        </div>
        <div className="w-100 d-flex justify-content-center my-3">
          <ButtonSubmit
            Title="Create"
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={submitFunc}
            pending={mutation?.isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default BoothForm;
