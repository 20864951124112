import React from "react";

import { Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { MdOutlineSettings } from "react-icons/md";
import { HiOutlineClipboardList } from "react-icons/hi";
import { MdStar } from "react-icons/md";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { FiFileText } from "react-icons/fi";
import { MdEqualizer } from "react-icons/md";

import classnames from "classnames";

export const SidebarBooth = ({ selectActiveTab, setSelectActiveTab }) => {
  const toggleSelectActiveTab = (tab) => {
    if (selectActiveTab !== tab) {
      setSelectActiveTab(tab);
    }
  };

  return (
    <div
      style={{
        paddingLeft: 17,
        paddingTop: 80,
      }}
    >
      <Nav pills className="nav flex-column nav-pills text-center">
        {/* <NavItem>
                  <NavLink
                    style={{ cursor: "pointer", width: 200 }}
                    className={classnames({
                      "mb-2": true,
                      active: selectActiveTab === "0",
                    })}
                    onClick={() => {
                      toggleVertical("0");
                    }}
                  >
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        <i className="dripicons-home font-size-18 d-block my-1"></i>
                      </div>
                      <div className="d-flex align-items-center mx-3">
                        Overview
                      </div>
                    </div>
                  </NavLink>
                </NavItem> */}
        <NavItem>
          <NavLink
            style={{
              cursor: "pointer",
              width: "100%",
            }}
            className={classnames({
              "mb-2 event-sidebar-text-color": true,
              active: selectActiveTab === "1",
            })}
            onClick={() => {
              toggleSelectActiveTab("1");
            }}
          >
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <MdOutlineSettings size={20} />
              </div>
              <div className="d-flex align-items-center mx-3">Summary</div>
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer", width: "100%" }}
            className={classnames({
              "mb-2": true,
              active: selectActiveTab === "2",
            })}
            onClick={() => {
              toggleSelectActiveTab("2");
            }}
          >
            <div className="d-flex event-sidebar-text-color">
              <div className="d-flex align-items-center">
                <HiOutlineClipboardList size={20} />
              </div>
              <div className="d-flex align-items-center mx-3">Leads</div>
            </div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer", width: "100%" }}
            className={classnames({
              "mb-2": true,
              active: selectActiveTab === "3",
            })}
            onClick={() => {
              toggleSelectActiveTab("3");
            }}
          >
            <div className="d-flex event-sidebar-text-color">
              <div className="d-flex align-items-center">
                <MdStar size={20} />
              </div>
              <div className="d-flex align-items-center mx-3">
                Booth Reviews
              </div>
            </div>
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};
