import React from "react";
import exhibition from "../../../assets/images/banners/login/exhibition.png";
import "./login-lotties.scss";

function Exhibition() {
  return (
    <div className="lotties-login-container">
      <div className="lotties-login-container">
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ userSelect: "none" }}
        >
          <div
            className="rounded-4 lotties-login-container-conference"
            style={{ width: "14em", height: "14em" }}
          >
            <img src={exhibition} alt="exhibition" className="w-100 h-100" />
          </div>
        </div>
        <div className="lotties-login-conference-caption">
          <div className="w-100 d-flex justify-content-center lotties-login-text">
            Experience the Future of Exhibitions:
          </div>
          <div className="w-100 d-flex justify-content-center lotties-login-text">
            Connect. Engage. Convert
          </div>
        </div>
      </div>
    </div>
  );
}

export default Exhibition;
