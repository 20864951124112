import React, { useState } from "react";
import { packageDescription } from "../../../../components/Authentication/RequestDemo/packageDescription";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FiMinusCircle } from "react-icons/fi";

const PackageList = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="request-demo-form-container-left">
      <div className="request-demo-form-container-left-title">Our Packages</div>
      {packageDescription?.map((faq, index) => (
        <div
          key={index}
          className={`request-demo-form-container-left-faq-item ${
            openIndex === index ? "active" : ""
          }`}
        >
          <div
            className="request-demo-form-container-left-faq-item-title"
            onClick={() => toggleFAQ(index)}
          >
            {faq?.name}
            <span className="request-demo-form-container-left-faq-item-title-icon">
              {openIndex === index ? (
                <FiMinusCircle
                  size={28}
                  color={openIndex === index ? "black" : "white"}
                />
              ) : (
                <IoMdAddCircleOutline
                  size={28}
                  color={openIndex === index ? "black" : "white"}
                />
              )}
            </span>
          </div>
          {openIndex === index && (
            <div className="request-demo-form-container-left-faq-item-content mt-2">
              {faq?.features?.map((feature, idx) => (
                <div key={idx} className="d-flex mt-1">
                  <span style={{ width: 20, height: 20 }}>
                    <FaCheckCircle size={15} color="#f68a20" />
                  </span>
                  <span className="mx-2">{feature}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PackageList;
