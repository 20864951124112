import React from "react";
import { TabContent, TabPane } from "reactstrap";
import SummaryBooth from "../../../Common/SetBooth/SummaryBooth";
import LeadBooth from "../../../Common/SetBooth/LeadBooth";
import ReviewBooth from "../../../Common/SetBooth/ReviewsBooth";

export const ContentBooth = ({
  eventName,
  eventID,
  boothSpaceId,
  selectActiveTab,
  boothNumber,
  liveBroadcast,
  duration,
  token,
  userEmail,
  boothID,
  boothDetails,
}) => {
  return (
    <div className="" style={{ width: "100%", height: "100vh" }}>
      <TabContent
        activeTab={selectActiveTab}
        className="text-muted mt-4 mt-md-0 "
      >
        {/* <TabPane tabId="1">
          <div className="p-5">
            <Overview
              eventDetails={getEventDetails?.data}
              token={token}
              userID={userID}
              isLoading={isLoadingGetEventDetails}
              userEmail={userEmail}
            />
          </div>
        </TabPane> */}
        <TabPane tabId="1">
          <div className="p-5">
            <SummaryBooth
              eventName={eventName}
              eventID={eventID}
              boothSpaceId={boothSpaceId}
              boothID={boothID}
              boothNumber={boothNumber}
              liveBroadcast={liveBroadcast}
              duration={duration}
              userEmail={userEmail}
              token={token}
              boothDetails={boothDetails}
            />
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className="p-5">
            <LeadBooth
              eventName={eventName}
              eventID={eventID}
              boothID={boothID}
              token={token}
            />
          </div>
        </TabPane>
        <TabPane tabId="3">
          <div className="p-5">
            <ReviewBooth
              eventName={eventName}
              eventID={eventID}
              boothID={boothID}
            />
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
};
