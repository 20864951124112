import React from "react";
import { Spinner } from "reactstrap";

function ButtonSize({
  height,
  width,
  Title,
  BackgroundColor,
  ColorText,
  BorderColor,
  borderRadius,
  handleOnclick,
  pending,
}) {
  return (
    <buton
      type="button"
      className="btn"
      style={{
        width: width,
        height: height,
        fontWeight: "600",
        color: ColorText,
        borderColor: BorderColor,
        borderWidth: "2px",
        borderStyle: "solid",
        borderRadius: borderRadius,
        backgroundColor: BackgroundColor,
      }}
      onClick={handleOnclick}
    >
      <div className="d-flex justify-content-center align-items-center">
        {!pending ? <span>{Title}</span> : null}
        {!pending ? null : (
          <span>
            <Spinner as="span" animation="border" size="sm" /> Loading
          </span>
        )}
      </div>
    </buton>
  );
}

export default ButtonSize;
