import React from "react";
import { Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import useCustomFetchQuery from "../../Hooks/useCustomFecthQuery";
import PayNowHeader from "./PayNowHeader";
import CheckoutPackageForm from "./CheckoutPackageForm";
import PackageDetails from "./PackageDetails";
import CustomFooter from "../../Layout/HorizontalLayout/CustomFooter";

const PayQuotation = () => {
  const params = useParams();
  const packageID = params?.packageID;

  const {
    data: getMyQuotationDetails,
    error: errorGetMyQuotationDetailss,
    isFetching: isFetchingGetMyQuotationDetails,
  } = useCustomFetchQuery(
    ["packageDetails", packageID],
    `${process.env.REACT_APP_API}packages/${packageID}`,
    null,
    { enabled: !!packageID }
  );

  // console.log(
  //   "getMyQuotationDetails....",
  //   JSON.stringify(getMyQuotationDetails, null, 2)
  // );

  return (
    <React.Fragment>
      <PayNowHeader />
      <div className="w-100 mt-2 d-flex justify-content-center">
        <Row>
          <Col md={8}>
            <div
              className="w-100 py-3 rounded-3"
              style={{ backgroundColor: "white" }}
            >
              <div className="px-4 py-2 h4">Checkout</div>
              <div className="px-4">
                <CheckoutPackageForm
                  packageData={getMyQuotationDetails?.data}
                />
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="w-100">
              <PackageDetails packageData={getMyQuotationDetails?.data} />
            </div>
          </Col>
        </Row>
      </div>
      <div className="w-100">
        <CustomFooter />
      </div>
    </React.Fragment>
  );
};

export default PayQuotation;
