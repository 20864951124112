import React, { useState } from "react";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import DataTable from "react-data-table-component";
import OffCanvas from "../../OffCanvas/OffCanvas";
import BoothNaviagtion from "./BoothNaviagtion";
import ViewLead from "./ViewLead";
import { AiFillEye } from "react-icons/ai";

const LeadBooth = ({ eventName, eventID, token, boothID }) => {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [leadDetails, setILeadDetails] = useState(null);

  const key = ["myLeads", boothID];
  const {
    data: getLeads,
    error: errorGetLeads,
    isLoading: isLoadingGetLeads,
  } = useCustomFecthQuery(
    key,
    `${process.env.REACT_APP_API}booth-leads/booth/${boothID}`,
    token,
    {
      enabled: !!boothID,
    }
  );

  console.log("getLeads....", JSON.stringify(getLeads?.data, null, 2));

  const tog_viewLeads = (details) => {
    setILeadDetails(details);
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const toggleOpenOffCanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Full name</span>,
      selector: (row) => row?.name,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone number</span>,
      selector: (row) => row?.phone,
    },
    {
      name: <span className="font-weight-bold fs-13">Note</span>,
      selector: (row) => row?.note,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => tog_viewLeads(row)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const data = getLeads?.data;

  return (
    <div className="set-event-rightbar-content">
      <div className="w-100 d-flex">
        <div
          className="px-4"
          style={{
            width: "100%",
            margin: "0px auto",
          }}
        >
          <BoothNaviagtion
            eventName={eventName}
            eventID={eventID}
            boothID={boothID}
          />
          <div className="analystic-table-card mt-4">
            <div className="w-100">
              <DataTable
                fixedHeader
                fixedHeaderScrollHeight="300px"
                columns={columns}
                responsive
                data={data}
                pagination
              />
            </div>
          </div>
        </div>
      </div>
      <OffCanvas
        isOffcanvasOpen={isOffcanvasOpen}
        title="View Lead"
        body={<ViewLead leadDetails={leadDetails} />}
        toggleSaveChanges={toggleOpenOffCanvas}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
    </div>
  );
};

export default LeadBooth;
