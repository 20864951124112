import React from "react";
import { FaRegCalendar } from "react-icons/fa";
import { MdOutlineTimer } from "react-icons/md";
import { HiDocumentText } from "react-icons/hi2";

const ViewSession = ({ sessionDetails }) => {
  return (
    <div className="w-100">
      <div className="w-100 mb-4">
        <h4>Topic: {sessionDetails?.sessionName}</h4>
      </div>
      <div className="w-100 mb-2 d-flex align-items-center gap-1">
        <FaRegCalendar size={18} />
        <div>{sessionDetails?.sessionDate}</div>
        <div className="d-flex mx-3">
          <div>{sessionDetails?.startTime}</div> {"-"}
          <div>{sessionDetails?.endTime}</div>
        </div>
      </div>
      <div className="w-100 mb-2 d-flex align-items-center gap-1">
        <MdOutlineTimer size={18} />

        <div>{sessionDetails?.duration} Minutes</div>
      </div>
      {sessionDetails?.speakers.length > 0 ? (
        <div className="w-100 mt-4">
          <h5>Speaker(s)</h5>
          <div className="w-100 mb-2 d-flex align-items-center gap-1">
            {sessionDetails?.speakers?.map((speaker, i) => {
              return (
                <div key={i}>
                  <div style={{ width: "100px" }}>
                    <div
                      style={{
                        width: "80px",
                        height: "80px",
                      }}
                    >
                      <img
                        src={speaker?.photo}
                        className="w-100 h-100"
                        alt="speaker-image"
                        style={{
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        width: "80px",
                      }}
                      className="d-flex justify-content-center"
                    >
                      {speaker?.firstName}{" "}
                      {`${speaker?.lastName}`.substring(0, 1)}.
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {sessionDetails?.additionalMaterial.length > 0 ? (
        <div className="mt-4">
          <h5>Session Document </h5>
          <div className="w-100 d-flex">
            {sessionDetails?.additionalMaterial?.map((document, i) => {
              return (
                <div
                  key={i}
                  style={{
                    width: "200px",
                    height: "200px",
                  }}
                >
                  <div className="w-100">
                    <HiDocumentText size={140} color="#f68a20" />
                  </div>
                  <div
                    style={{
                      marginLeft: "10%",
                      fontWeight: "bolder",
                      fontSize: 13,
                      color: "#f68a20",
                    }}
                  >
                    {document?.name}
                  </div>
                </div>
              );
            })}{" "}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ViewSession;
