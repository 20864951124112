import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import useUserFecthQuery from "../../Hooks/useUserFecthQuery";
import Profile from "./components/Profile/Profile";
import OrganizerProfile from "./components/Profile/OragnizerProfile";
import ParticipantsProfile from "./components/Profile/ParticipantProfile";
import UpdatePassword from "./components/Profile/UpdatePassword";
import DeleteAccount from "./components/Profile/DeleteAccount";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
// import { editProfile, resetProfileFlag } from "../../store/actions";

const UserProfile = () => {
  document.title = " Kago Events | Profile";

  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  // const userDetails = obj;
  const userInfo = obj?.data;
  const userId = obj?.data?.data?.id;
  const token = obj?.data?.token;

  const {
    data: getUserDetails,
    error: errorGetUserDetails,
    isLoading: isLoadingGetUserDetails,
  } = useUserFecthQuery(
    ["MyUserDetails", userId],
    `${process.env.REACT_APP_API}users/${userId}`,
    token,
    { enabled: !!userId }
  );

  const getOffsetTop = (element) => {
    let offsetTop = 0;
    while (element) {
      offsetTop += element.offsetTop;
      element = element.offsetParent;
    }
    return offsetTop;
  };

  const isEventOrganizer = userInfo?.data?.roles?.includes("event_organizer");

  const scrollToElement = (elementId) => {
    //   const element = document.getElementById(elementId);
    //   if (element) {
    //     element.scrollIntoView({ behavior: "smooth" });
    //   }
    const element = document.getElementById(elementId);
    if (element) {
      const offsetTop = getOffsetTop(element);
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content" style={{ userSelect: "none" }}>
        <Container fluid>
          <Breadcrumb title="Kago Events" breadcrumbItem="Profile" />
          <Row>
            <Col md={2}></Col>
            <Col md={8} id="profile">
              <Card>
                <CardBody
                  className="w-100 p-4 profile-container"
                  style={{ userSelect: "none" }}
                >
                  <Profile
                    userId={userId}
                    isEventOrganizer={isEventOrganizer}
                    userData={getUserDetails}
                    token={getUserDetails?.data?.token}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody
                  id="profile-details"
                  className="w-100 p-4 profile-container"
                  style={{ userSelect: "none" }}
                >
                  <div className="profile-form-title">Profile Details</div>
                  {isEventOrganizer ? (
                    <OrganizerProfile
                      userId={userId}
                      userData={getUserDetails}
                      token={getUserDetails?.data?.token}
                    />
                  ) : (
                    <ParticipantsProfile
                      userId={userId}
                      userData={getUserDetails}
                      token={getUserDetails?.data?.token}
                    />
                  )}
                </CardBody>
              </Card>

              <Card>
                <CardBody
                  id="password"
                  className="w-100 p-4 profile-container"
                  style={{ userSelect: "none" }}
                >
                  <div className="profile-form-title">Change Password</div>
                  <UpdatePassword userData={getUserDetails} token={token} />
                </CardBody>
              </Card>
              <Card>
                <CardBody
                  id="account"
                  className="w-100 p-4 profile-container"
                  style={{ userSelect: "none" }}
                >
                  <div className="profile-form-title">Delete Account</div>
                  <DeleteAccount userId={userId} token={token} />
                </CardBody>
              </Card>
            </Col>
            <Col md={2}></Col>
          </Row>

          {/* <h4 className="card-title mb-4">Update Profile</h4>

          <Card>
            <CardBody></CardBody>
          </Card> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
