import React, { useState } from "react";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import ButtonIcon from "../../../Buttons/ButtonIcon";
import OffCanvas from "../../../OffCanvas/OffCanvas";

import SearchInput from "../../../SerachInput/SearchInput";
import InvitesTab from "./InvitesTab/InvitesTab";
import TableInvites from "./TableInvites";

const Invites = ({ userID, token, eventID }) => {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleOpenOffCanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  return (
    <div className="w-100" style={{ paddingTop: "5%" }}>
      {/* <div style={{ fontSize: 18, fontWeight: "600" }}>Invitations</div> */}

      <div className="w-100 mt-3 ">
        <div className="w-100">Manage all invited attendees.</div>
        <div className="w-100 mt-3 d-flex">
          <div className="w-75 d-flex">
            <SearchInput
              Title="Search"
              search={search}
              setSearch={setSearch}
              handleSearch={handleSearch}
              radius={25}
            />
          </div>
          <div className="w-25 d-flex justify-content-end">
            <ButtonIcon
              Title="Invite Attendee"
              Icon={<AiOutlineUsergroupAdd size={20} color="white" />}
              BackgroundColor="#f68a20"
              ColorText="white"
              BorderColor="#f68a20"
              borderRadius={20}
              handleOnclick={() => handleOpenOffCanvas()}
              pending={false}
            />
          </div>
        </div>
        <div>
          <TableInvites
            userID={userID}
            token={token}
            eventID={eventID}
            search={search}
          />
        </div>
      </div>
      <div className="w-100 mt-3"></div>
      <OffCanvas
        isOffcanvasOpen={isOffcanvasOpen}
        title="Invite Attendee"
        body={
          <InvitesTab
            userID={userID}
            token={token}
            eventID={eventID}
            setIsOffcanvasOpen={setIsOffcanvasOpen}
          />
        }
        toggleSaveChanges={handleOpenOffCanvas}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
    </div>
  );
};

export default Invites;
