import React from "react";
import CreateBooth from "./CreateBooth";
import CreateCompanyProfile from "./CreateCompanyProfile";
import BoothDetails from "./BoothDetails";
import BoothCompany from "./BoothCompany";

import BoothNaviagtion from "./BoothNaviagtion";

const SummaryBooth = ({
  eventName,
  eventID,
  boothSpaceId,
  boothID,
  liveBroadcast,
  userEmail,
  token,
  duration,
  boothDetails,
}) => {
  return (
    <div className="set-event-rightbar-content">
      <div className="w-100 d-flex">
        <div
          className="px-4"
          style={{
            width: "100%",
            margin: "0px auto",
          }}
        >
          <BoothNaviagtion
            eventName={eventName}
            eventID={eventID}
            boothID={boothID}
          />
          {boothID ? (
            <BoothDetails boothDetails={boothDetails} />
          ) : (
            <CreateBooth
              eventID={eventID}
              boothSpaceId={boothSpaceId}
              liveBroadcast={liveBroadcast}
              duration={duration}
              token={token}
              userEmail={userEmail}
            />
          )}
          {boothID ? <BoothCompany /> : <CreateCompanyProfile />}
        </div>
      </div>
    </div>
  );
};

export default SummaryBooth;
