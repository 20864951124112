import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import boothbgImg from "../../../../assets/images/background/booth-bg.png";
import Search from "./Search";
import "./.receptionLobby.scss";
import boothOne from "../../../../assets/images/booths/1.png";
import boothTwo from "../../../../assets/images/booths/2.png";
import boothThree from "../../../../assets/images/booths/3.png";
import boothFour from "../../../../assets/images/booths/4.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Booths = ({ eventName, eventID, token }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [boothDetail, setBoothDetail] = useState(null);

  const {
    data: getEventBooths,
    error: errorGetEventBooths,
    isLoading: isLoadingGetEventBooths,
  } = useCustomFecthQuery(
    ["eventBooths", eventID],
    `${process.env.REACT_APP_API}booths/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const toggleEnterBooth = (boothID, boothName) => {
    navigate(`/${eventName}/booth/${boothName?.replace(/ /g, "-")}/${boothID}`);
  };

  const filterBoothsByName = (booths, searchQuery) => {
    if (!searchQuery) {
      return booths;
    } else {
      return booths?.filter((booth) =>
        booth?.boothName?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  };
  const filteredBooths = filterBoothsByName(getEventBooths?.data, search);

  return (
    <div className="btn-booth-booths-container">
      <div
        style={{
          backgroundImage: `url(${boothbgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <div className="booth-search-container">
          <Search search={search} setSearch={setSearch} />
        </div>
        <div className="">
          <div className="booth-reception-container">
            <Swiper
              spaceBetween={30}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              breakpoints={{
                1290: {
                  slidesPerView: 3,
                },
                900: {
                  slidesPerView: 2,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
              className="booth-swipper-slides"
            >
              {filteredBooths?.map((item, i) => {
                return (
                  <SwiperSlide key={i} style={{ background: "transparent" }}>
                    <div className="booth-reception-wrapper">
                      <div className="booth-reception-wrapper-img">
                        <img
                          src={
                            item?.boothImage === "1"
                              ? boothOne
                              : item?.boothImage === "2"
                              ? boothTwo
                              : item?.boothImage === "3"
                              ? boothThree
                              : item?.boothImage === "4"
                              ? boothFour
                              : null
                          }
                          alt="booth-image"
                          className="w-100 h-100"
                        />
                        <div className="company-exhibition-lobby">
                          <img src={item?.boothLogo} alt="company-logo" />
                        </div>
                        <div className="company-name-lobby">
                          {item?.boothName}
                        </div>
                        <button
                          className="btn-exhibition-lobby btn-enter-booth"
                          onClick={() =>
                            toggleEnterBooth(item?._id, item?.boothName)
                          }
                        >
                          Enter Booth
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booths;
