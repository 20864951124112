import React from "react";
import { Spinner } from "reactstrap";

function ButtonGradient({
  Title,
  leftColor,
  rightColor,
  ColorText,
  BorderColor,
  borderRadius,
  handleOnclick,
  pending,
}) {
  return (
    <buton
      type="button"
      className="btn"
      style={{
        fontWeight: "600",
        color: ColorText,
        borderColor: BorderColor,
        borderWidth: "2px",
        borderStyle: "solid",
        borderRadius: borderRadius,
        background: `linear-gradient(to right, ${leftColor}, ${rightColor})`,
      }}
      onClick={handleOnclick}
    >
      {!pending ? (
        <span>{Title}</span>
      ) : (
        <span>
          <Spinner as="span" animation="border" size="sm" /> Loading
        </span>
      )}
    </buton>
  );
}

export default ButtonGradient;
