import React from "react";
import { TabContent, TabPane } from "reactstrap";
import EventSetup from "../../EventBranding/EventSetup";
import EventBranding from "../../EventBranding/EventBranding";
import Participants from "../../Participants/Participants";
import Registration from "../../Registrattion/Registration";
import Sessions from "../../Sessions/Sessions";
import Exhibition from "../../Exhibition/Exhibition";
import Marketing from "../../Marketing/Marketing";
import Analytics from "../../Analytics/Analytics";

export const Content = ({
  selectActiveTab,
  getEventDetails,
  isLoadingGetEventDetails,
  token,
  userID,
  userEmail,
}) => {
  return (
    <div className="" style={{ width: "100%", height: "100vh" }}>
      <TabContent
        activeTab={selectActiveTab}
        className="text-muted mt-4 mt-md-0 "
      >
        {/* <TabPane tabId="1">
          <div className="p-5">
            <Overview
              eventDetails={getEventDetails?.data}
              token={token}
              userID={userID}
              isLoading={isLoadingGetEventDetails}
              userEmail={userEmail}
            />
          </div>
        </TabPane> */}
        <TabPane tabId="1">
          <div className="p-5">
            <EventSetup
              eventDetails={getEventDetails?.data}
              token={token}
              userID={userID}
              isLoading={isLoadingGetEventDetails}
              userEmail={userEmail}
            />
          </div>
        </TabPane>

        <TabPane tabId="2">
          <div className="px-5 py-5">
            <EventBranding
              eventDetails={getEventDetails?.data}
              token={token}
              userID={userID}
              isLoading={isLoadingGetEventDetails}
              userEmail={userEmail}
            />
          </div>
        </TabPane>
        <TabPane tabId="3">
          <Registration
            eventDetails={getEventDetails?.data}
            token={token}
            userID={userID}
            isLoading={isLoadingGetEventDetails}
          />
        </TabPane>
        <TabPane tabId="4">
          <Participants
            eventDetails={getEventDetails?.data}
            token={token}
            userID={userID}
            isLoading={isLoadingGetEventDetails}
          />
        </TabPane>
        <TabPane tabId="5">
          <div className="px-5 py-5">
            <Sessions
              eventDetails={getEventDetails?.data}
              token={token}
              userID={userID}
              isLoading={isLoadingGetEventDetails}
            />
          </div>
        </TabPane>
        <TabPane tabId="6">
          <Exhibition
            eventDetails={getEventDetails?.data}
            token={token}
            userID={userID}
            isLoading={isLoadingGetEventDetails}
          />
        </TabPane>

        <TabPane tabId="7">
          {/* <div className="p-5"> */}
          <Marketing
            eventDetails={getEventDetails?.data}
            token={token}
            userID={userID}
            isLoading={isLoadingGetEventDetails}
          />
          {/* </div> */}
        </TabPane>
        <TabPane tabId="8">
          <div className="p-5">
            <Analytics
              eventDetails={getEventDetails?.data}
              token={token}
              userID={userID}
              isLoading={isLoadingGetEventDetails}
            />
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
};
