import React from "react";
import Select from "react-select";
import TopBarRequestDemo from "../../../components/Common/TopBarRequestDemo";
import RequestForm from "./RequestForm";

// import Banner from "./Banner";

import FAQ from "./FAQ";
import RequestCard from "./RequestCard";
import "../../../assets/scss/custom/pages/_requestDemo.scss";

const Index = () => {
  return (
    <>
      <TopBarRequestDemo />
      {/* <div style={{ marginTop: "4%" }}></div> */}
      {/* <Banner /> */}
      <div className="w-100 pt-4 pb-3" style={{ backgroundColor: "#F7F7F7" }}>
        <RequestForm />
      </div>
      <FAQ />
      <div className="pb-5" style={{ backgroundColor: "#F7F7F7" }}>
        <RequestCard />
      </div>
    </>
  );
};

export default Index;
