import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import ViewExhibitor from "./ViewExhibitor";
import EditExhibitor from "./EditExhibitor";
import ModalDelete from "../../../Modal/ModalDelete";
import DeleteForm from "./DeleteForm";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import { FaEdit } from "react-icons/fa";
import { AiFillEye, AiFillDelete } from "react-icons/ai";

const TableExhibitors = ({ token, eventID, search, eventFormat }) => {
  const [exhibitorID, setExhibitorID] = useState(null);
  const [exhibitorDetails, setExhibitorDetails] = useState(null);
  const [isToggleView, setIsToggleView] = useState(false);
  const [isToggleEdit, setIsToggleEdit] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);

  const key = ["myExhibitors", eventID];

  const mutationDelete = useCustomMutation(key);

  const {
    data: getExhibitors,
    error: errorGetExhibitors,
    isLoading: isLoadingGetExhibitors,
  } = useCustomFecthQuery(
    key,
    `${process.env.REACT_APP_API}exhibitors/${eventID}`,
    token,
    {
      enabled: !!eventID,
    }
  );

  const tog_viewParticipant = (details) => {
    setExhibitorDetails(details);
    setIsToggleView(!isToggleView);
  };
  const tog_editParticipant = (details) => {
    setExhibitorDetails(details);
    setIsToggleEdit(!isToggleEdit);
  };
  const tog_deleteParticipant = (id) => {
    setExhibitorID(id);
    setIsToggleDelete(!isToggleDelete);
  };

  const DeleteParticitant = () => {
    const Method = "DELETE",
      url = `exhibitors/${exhibitorID}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Exhibitor successfully deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Booth Number</span>,
      selector: (row) => row?.boothNumber,
    },
    {
      name: <span className="font-weight-bold fs-13">Organization</span>,
      selector: (row) => row?.organization,
    },
    {
      name: <span className="font-weight-bold fs-13">First Name</span>,
      selector: (row) => row?.firstName,
    },
    {
      name: <span className="font-weight-bold fs-13">Last Name</span>,
      selector: (row) => row?.lastName,
    },

    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone number</span>,
      selector: (row) => row?.organizationNumber,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div className="d-flex">
            <span>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => tog_viewParticipant(row)}
              />
            </span>
            <span>
              <FaEdit
                size={18}
                className="mx-1"
                onClick={() => tog_editParticipant(row)}
              />
            </span>

            <span>
              <AiFillDelete
                size={20}
                onClick={() => tog_deleteParticipant(row?._id)}
              />
            </span>
          </div>
        );
      },
    },
  ];

  const filterParticipants = (participants, searchQuery) => {
    if (!searchQuery) return participants;
    return participants?.filter((item) =>
      [item?.firstName, item?.lastName, item?.email, item?.jobTitle].some(
        (field) => field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };
  const filteredSpeakers = filterParticipants(getExhibitors?.data, search);

  const data = filteredSpeakers;
  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setIsToggleDelete(false);
    }
  }, [mutationDelete.isSuccess]);
  return (
    <div className="mt-3 w-100">
      <div className="analystic-table-card">
        <div className="w-100">
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </div>
      </div>
      <ModalDelete
        size="md"
        isOpen={isToggleDelete}
        toggle={() => {
          setIsToggleDelete(false);
        }}
        title="Delete Exhibitor"
        body={
          <DeleteForm
            setIsToggleDelete={setIsToggleDelete}
            DeleteParticitant={DeleteParticitant}
            pending={mutationDelete?.isLoading}
          />
        }
      />
      <OffCanvas
        isOffcanvasOpen={isToggleView}
        title="Exhibitor Details"
        body={<ViewExhibitor details={exhibitorDetails} />}
        toggleSaveChanges={() => tog_viewParticipant()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
      <OffCanvas
        isOffcanvasOpen={isToggleEdit}
        title="Update Exhibitor Details"
        body={
          <EditExhibitor
            details={exhibitorDetails}
            token={token}
            eventID={eventID}
            setIsToggleEdit={setIsToggleEdit}
            eventFormat={eventFormat}
          />
        }
        toggleSaveChanges={() => tog_editParticipant()}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
    </div>
  );
};

export default TableExhibitors;
