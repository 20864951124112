import React, { useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
// import CustomModal from "../../../../components/Modal/CustomModal";
import OffCanvas from "../../../../components/OffCanvas/OffCanvas";
import FullModal from "../../../../components/Modal/FullModal";
// import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
// import LoaderMySubscription from "../../../../components/Loader/LoaderSubscriptions";
import "./css/SelectOption.scss";
// import Conference from "./Conference";
// import Exhibition from "./Exhibition";
// import Webinar from "./Webinar";
// import Expo from "./Expo";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Link } from "react-router-dom";
// import { pricing } from "../../../../components/Common/eventPackage/EventPackage";
import { subscriptionStyles } from "../../../../assets/styles/subscription";
import { FaCheck } from "react-icons/fa";
import EventForm from "./EventForm";
import UpgradeForm from "./UpgradeForm";

function Subscription({ getCommunities, packages, token, userDetails }) {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userID = obj?.data?.data?._id;
  // const userEmail = obj?.data?.data?.email;
  // const token = obj?.data?.token;
  // const packages = obj?.data?.data?.packages;

  const [packageID, setPackageID] = useState(null);
  const [eventService, setEventService] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [eventFormat, setEventFormat] = useState(null);
  const [isCreateEvent, setIsCreateEvent] = useState(false);
  const [isUpgradeEvent, setIsUpgradeEvent] = useState(false);

  const toggleCreateEvent = (id, serviceName, eventFormat, type) => {
    setPackageID(id);
    setEventService(serviceName);
    setEventType(type);
    setEventFormat(eventFormat);
    setIsCreateEvent(!isCreateEvent);
  };

  const handleUpgrade = () => {
    setIsUpgradeEvent(true);
  };

  return (
    <>
      <div className="w-100 d-flex justify-content-center">
        <div style={subscriptionStyles.container}>
          <h2 style={subscriptionStyles.title}>Create Events</h2>
          <Row>
            {packages?.data?.map((item, i) => {
              // console.log("item...", JSON.stringify(item, null, 2));
              if (item?.redeemed === false) {
                return (
                  <Col md={6} key={i} className="mb-3">
                    <div
                      style={{
                        ...subscriptionStyles.planCard,
                        ...subscriptionStyles.selectedPlan,
                      }}
                    >
                      <div style={subscriptionStyles.cardIcon}>
                        <FaCheck size={15} color="white" />
                      </div>

                      <div>
                        <div style={subscriptionStyles.planHeader}>
                          <span style={subscriptionStyles.planName}>
                            {item?.service}
                          </span>
                          <div style={subscriptionStyles.price}>
                            <span style={subscriptionStyles.priceAmount}>
                              R{item?.price}
                            </span>
                            {/* <span style={subscriptionStyles.priceInterval}>
                        /month
                      </span> */}
                          </div>
                        </div>
                        <div style={subscriptionStyles.activationNotice}>
                          <span className="fw-bold text-dark">
                            Your package includes:{" "}
                          </span>
                          <span>{item?.attendeeCapacity} Attendees</span>
                          <span>, {item?.lead?.eventType} Event</span>
                          <span>, {item?.lead?.eventFormat} Event</span>
                          <span>, {item?.duration} minutes</span>

                          {}
                        </div>
                      </div>
                      <button
                        style={subscriptionStyles.createEvent}
                        onClick={() => {
                          toggleCreateEvent(
                            item?._id,
                            item?.service,
                            item?.lead?.eventFormat,
                            item?.lead?.eventType
                          );
                        }}
                      >
                        Create Event
                      </button>
                    </div>
                  </Col>
                );
              }
            })}
            <Col md={6}>
              <div
                style={{
                  ...subscriptionStyles.planCard,
                  ...subscriptionStyles.contactUsPlan,
                }}
              >
                <div>
                  <div style={subscriptionStyles.planHeader}>
                    <span
                      style={{
                        ...subscriptionStyles.planName,
                        fontSize: 16,
                        fontWeight: "600",
                      }}
                    >
                      Contact Us
                    </span>
                  </div>
                  <div
                    style={{
                      ...subscriptionStyles.activationNotice,
                      ...subscriptionStyles.whiteText,
                    }}
                  >
                    Want to update your subscription? Please click below.
                  </div>
                </div>
                <div style={subscriptionStyles.buttonContainer}>
                  <button
                    style={subscriptionStyles.upgradeButton}
                    onClick={handleUpgrade}
                  >
                    Upgrade
                  </button>
                </div>
              </div>
            </Col>
          </Row>

          <p style={subscriptionStyles.toggleDescription}>
            This option, if checked, will renew your subscription automatically
            when the current plan expires.
          </p>
        </div>
      </div>
      <FullModal
        isOpen={isCreateEvent}
        toggleEvent={() => setIsCreateEvent(false)}
        body={
          <EventForm
            eventType={eventType}
            eventFormat={eventFormat}
            packageID={packageID}
            eventService={eventService}
            userId={userID}
            token={token}
            getCommunities={getCommunities?.data}
          />
        }
      />
      <FullModal
        isOpen={isUpgradeEvent}
        toggleEvent={() => setIsUpgradeEvent(false)}
        body={
          <UpgradeForm
            eventType={eventType}
            setIsUpgradeEvent={setIsUpgradeEvent}
            userDetails={userDetails}
            userID={userID}
            token={token}
          />
        }
      />
    </>
  );
}

export default Subscription;
