import React from "react";
import ConferenceAnalytics from "./ConferenceAnalytics";
import ExhibitionAnalytics from "./ExhibitionAnalytics";
import ExpoAnalytics from "./ExpoAnalytics";

const Analytics = ({ eventDetails, token, userID, isLoading }) => {
  // console.log("eventDetails...", eventDetails);
  // console.log("token...", token);
  // console.log("userID...", userID);
  // console.log("isLoading...", isLoading);
  return (
    <React.Fragment>
      <div className="p-3">
        {eventDetails?.eventType?.includes("Conference") ? (
          <ExpoAnalytics eventID={eventDetails?._id} token={token} />
        ) : null}
        {eventDetails?.eventType?.includes("Expo") ? (
          <ExpoAnalytics eventID={eventDetails?._id} token={token} />
        ) : null}
        {eventDetails?.eventType?.includes("Exhibition") ? (
          <ExpoAnalytics eventID={eventDetails?._id} token={token} />
        ) : null}
        {/* <ExpoAnalytics eventID={eventDetails?._id} token={token} /> */}
      </div>
    </React.Fragment>
  );
};

export default Analytics;
