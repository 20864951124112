import React from "react";
import { useNavigate } from "react-router-dom";
import SentMail from "../../components/Loader/SentMail";
import ButtonSubmit from "../../components/Buttons/ButtonStyle";

const InboxMessage = () => {
  const Navigate = useNavigate();
  const handleRedirection = () => {
    Navigate("/login");
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="successFullMessage-container d-flex justify-content-center align-items-center">
        <div
          style={{
            width: 500,
            backgroundColor: "white",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <div className="mt-4">
            <div
              className="w-100 d-flex justify-content-center"
              style={{
                userSelect: "none",
                color: "#f68a20",
                fontSize: 18,
                fontWeight: "800",
              }}
            >
              Email Sent
            </div>
            <div className="w-100 d-flex justify-content-center ">
              <SentMail title="Check out your email" />
            </div>
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <ButtonSubmit
              Title="OK"
              BackgroundColor="#f68a20"
              ColorText="white"
              BorderColor="#f68a20"
              borderRadius={15}
              handleOnclick={() => handleRedirection()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InboxMessage;
