import React from "react";
import SimpleBar from "simplebar-react";
import { FaEdit } from "react-icons/fa";
import ButtonIcon from "../../Buttons/ButtonIcon";
import boothOne from "../../../assets/images/booths/1.png";
import boothTwo from "../../../assets/images/booths/2.png";
import boothThree from "../../../assets/images/booths/3.png";
import boothFour from "../../../assets/images/booths/4.png";

const BoothDetails = ({ boothDetails }) => {
  console.log("boothDetails.....", JSON.stringify(boothDetails, null, 2));
  return (
    <div className="mt-3 mb-5 d-flex justify-content-center">
      <div
        style={{ width: "100%" }}
        className="border border-primary rounded-4 p-3"
      >
        <div className="w-100 mb-2 d-flex justify-content-between">
          <div
            className="h5"
            style={{
              width: 250,
            }}
          >
            {boothDetails?.boothName}
          </div>
          <div className="">
            <ButtonIcon
              Title="Edit Booth"
              BackgroundColor="#f68a20"
              ColorText="#fff"
              BorderColor="#f68a20"
              borderRadius={20}
              handleOnclick={() => {}}
              Icon={<FaEdit size={15} color="#fff" />}
              pending={false}
            />
          </div>
        </div>
        <div className="w-100 d-flex gap-3">
          <div
            style={{
              width: 250,
              height: 250,
              borderRadius: 10,
              background: "white",
            }}
          >
            <img
              src={
                boothDetails?.boothImage === "1"
                  ? boothOne
                  : boothDetails?.boothImage === "2"
                  ? boothTwo
                  : boothDetails?.boothImage === "3"
                  ? boothThree
                  : boothDetails?.boothImage === "4"
                  ? boothFour
                  : null
              }
              alt="booth-img"
              className="w-100 h-100"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div
            style={{
              width: "100%",
              height: 250,
              borderRadius: 10,
            }}
          >
            <div className="w-100 px-3">
              <div className="w-100">
                <p style={{ fontSize: 16, fontWeight: "600", color: "black" }}>
                  {boothDetails?.organization}
                </p>
                <SimpleBar
                  style={{
                    width: "100%",
                    height: 150,
                    borderRadius: 10,
                  }}
                >
                  <p>{boothDetails?.boothDescription}</p>
                </SimpleBar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoothDetails;
