import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import Slide from "../../components/Authentication/Slide";
import Form from "../../components/Authentication/Form";

const Login = (props) => {
  document.title = "Kago Events | Login";
  return (
    <div
      className="authentication-container"
      style={{ backgroundColor: "#f9c393" }}
    >
      <div className="authentication-container-form">
        <div className="authentication--wrapper">
          <div className="login--slider-container">
            <div className="login--slider-wrapper">
              <Slide />
            </div>
          </div>
          <div className="p-2 login--form--slider-container d-flex">
            <div className="login--form--slider-container--first"></div>

            <div
              className="login--form--slider-container--second d-flex align-items-center"
              style={{
                userSelect: "none",
              }}
            >
              <div className="h-100 d-flex align-items-center">
                <div
                  className="w-100"
                  style={{
                    userSelect: "none",
                  }}
                >
                  <div className="w-100 d-flex justify-content-center ">
                    <Form props={props} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="trade-mark">
        <Link
          to="https://www.tngsolutions.co.za/"
          target="_blank"
          rel="noopener noreferrer"
          className="trade-mark-text"
        >
          By TNG Solutions | Developed in South Africa
        </Link>
      </div>
    </div>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
