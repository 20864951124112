import React, { useState } from "react";
import DataTable from "react-data-table-component";

import OffCanvas from "../../../OffCanvas/OffCanvas";
import { AiFillEye } from "react-icons/ai";

const TableSms = ({ mySMS }) => {
  const [smsDetails, setSMSDetails] = useState(null);
  const [isToggleView, setIsToggleView] = useState(false);

  const tog_viewSMS = (details) => {
    setSMSDetails(details);
    setIsToggleView(!isToggleView);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hh = String(date.getHours()).padStart(2, "0");
    const mm = String(date.getMinutes()).padStart(2, "0");
    return `${hh}:${mm}`;
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Audience</span>,
      selector: (row) => row?.audience,
    },
    {
      name: <span className="font-weight-bold fs-13">Message</span>,
      cell: (row) => {
        return (
          <div>
            {row?.body?.length > 0
              ? `${row?.body?.substring(0, 20)}...`
              : row?.body}
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Sent By</span>,
      cell: (row) => {
        return (
          <div>
            <span>{row?.userId?.firstName}</span>
            <span className="mx-1">
              {" "}
              {row?.userId?.lastName?.substring(0, 1)}
            </span>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Date</span>,
      selector: (row) => formatDate(row?.createdAt),
    },
    {
      name: <span className="font-weight-bold fs-13">Time</span>,
      selector: (row) => formatTime(row?.createdAt),
    },
    {
      name: <span className="font-weight-bold fs-13">View</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <AiFillEye
                size={20}
                className=""
                onClick={() => tog_viewSMS(row)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const data = mySMS;

  return (
    <div className="mt-3 w-100">
      <div className="analystic-table-card-mb-none">
        <div className="w-100">
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </div>
      </div>

      <OffCanvas
        isOffcanvasOpen={isToggleView}
        title="SMS"
        body={
          <div>
            <div>
              <span style={{ fontWeight: "bold" }}>To :</span>{" "}
              <span>{smsDetails?.audience}</span>
            </div>

            <div
              className="mt-2 rounded-3 p-2 text-justify"
              style={{
                border: "0.5px solid #CACACA",
                backgroundColor: "#F0F0F0",
                minHeight: 200,
              }}
            >
              {smsDetails?.body}
            </div>
          </div>
        }
        toggleSaveChanges={() => tog_viewSMS()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
    </div>
  );
};

export default TableSms;
