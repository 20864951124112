import React from "react";
import { useParams } from "react-router-dom";
import LobbyConferenceComponent from "../../components/Common/EventLobby/EventConferenceOne";

const EventConferenceLobby = () => {
  document.title = "Kago Events | Event Reception";
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userId = obj?.data?.data?.id;
  const userEmail = obj?.data?.data?.email;
  const token = obj?.data?.token;
  const params = useParams();
  const eventID = params.eventID;
  const eventName = params.eventName;

  return (
    <React.Fragment>
      <LobbyConferenceComponent
        userEmail={userEmail}
        token={token}
        eventID={eventID}
        eventName={eventName}
      />
    </React.Fragment>
  );
};

export default EventConferenceLobby;
