import React, { useState } from "react";
import ButtonIcon from "../../Buttons/ButtonIcon";
import { IoMdAddCircleOutline } from "react-icons/io";
import OffCanvas from "../../OffCanvas/OffCanvas";
import BoothForm from "./BoothForm";

const CreateBooth = ({
  eventID,
  liveBroadcast,
  boothSpaceId,
  userEmail,
  duration,
  token,
}) => {
  const [isBoothOpen, setIsBoothOpen] = useState(false);
  const toogleCreateBooth = () => {
    setIsBoothOpen(!isBoothOpen);
  };
  return (
    <div className="mt-3 mb-5 d-flex justify-content-center">
      <div
        style={{ width: "100%", height: 200 }}
        className="border border-primary rounded-4 p-3 d-flex justify-content-center align-items-center"
      >
        <ButtonIcon
          Title="Setup Booth"
          BackgroundColor="#f68a20"
          ColorText="white"
          BorderColor="#f68a20"
          borderRadius={20}
          handleOnclick={toogleCreateBooth}
          Icon={<IoMdAddCircleOutline color="white" size={20} />}
          pending={false}
        />
      </div>
      <OffCanvas
        isOffcanvasOpen={isBoothOpen}
        title="Setup Booth"
        // body={<ShowSpeaker speaker={speakerDetails} showContacts={false} />}
        body={
          <BoothForm
            boothLiveBroadcast={liveBroadcast}
            userEmail={userEmail}
            eventID={eventID}
            boothSpaceId={boothSpaceId}
            duration={duration}
            token={token}
            setIsBoothOpen={setIsBoothOpen}
          />
        }
        toggleSaveChanges={() => setIsBoothOpen(false)}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
    </div>
  );
};

export default CreateBooth;
