import React from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";

import ShowEvents from "./components/ShowEvents";

const Dashboard = () => {
  document.title = "Kago Events | My events";
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userRole = obj?.data?.data?.roles;
  const userId = obj?.data?.data?.id;
  const userEmail = obj?.data?.data?.email;
  const token = obj?.data?.token;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="page-content" style={{ userSelect: "none" }}>
        <Container>
          <ShowEvents
            t={t}
            userId={userId}
            userEmail={userEmail}
            token={token}
            userRole={userRole}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Dashboard;
