import React from "react";
import { Container } from "reactstrap";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import useUserFecthQuery from "../../../Hooks/useUserFecthQuery";
import LoaderCommunityItem from "../../../components/Loader/LoaderCommunityItem";
import CreateCommunity from "./CreateCommunity";
import SelectOption from "./Components/SelectOption";
import Subscription from "./Components/Subscription";

const Index = () => {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userId = obj?.data?.data?.id;
  const userEmail = obj?.data?.data?.email;
  const token = obj?.data?.token;

  const {
    data: getCommunities,
    error: errorGetCommunities,
    isLoading: isLoadingGetCommunities,
  } = useCustomFecthQuery(
    ["MyCommunities", userEmail],
    `${process.env.REACT_APP_API}communities/user/${userEmail}`,
    token,
    { enabled: !!userEmail }
  );

  const {
    data: getUserDetails,
    error: errorGetUserDetails,
    isLoading: isLoadingGetUserDetails,
  } = useUserFecthQuery(
    ["MyUserDetails", userId],
    `${process.env.REACT_APP_API}users/${userId}`,
    token,
    { enabled: !!userId }
  );

  const {
    data: getUserPackage,
    error: errorGetUserPackage,
    isLoading: isLoadingGetUserPackage,
  } = useUserFecthQuery(
    ["MyUserPackage", userId],
    `${process.env.REACT_APP_API}packages/user/?email=${userEmail}`,
    token,
    { enabled: !!userId }
  );

  // console.log(
  //   "getPackageDetails...",
  //   JSON.stringify(getUserDetails?.data, null, 2)
  // );

  if (isLoadingGetUserPackage) {
    return (
      <div className="page-content">
        <LoaderCommunityItem />
      </div>
    );
  }

  if (isLoadingGetCommunities) {
    return (
      <div className="page-content">
        <LoaderCommunityItem />
      </div>
    );
  }

  // console.log("userEmail...", JSON.stringify(userEmail, null, 2));

  if (getCommunities?.data?.length === 0 && getCommunities?.status === "ok") {
    return (
      <React.Fragment>
        <div className="page-content">
          <CreateCommunity
            userId={userId}
            userEmail={userEmail}
            token={token}
          />
        </div>
      </React.Fragment>
    );
  }

  // console.log(
  //   "getCommunities....",
  //   JSON.stringify(getCommunities?.data, null, 2)
  // );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          {/* <SelectOption userId={userId} token={token} /> */}
          <Subscription
            getCommunities={getCommunities}
            userDetails={getUserDetails?.data}
            // packages={getUserDetails?.data?.data?.packages}
            packages={getUserPackage?.data}
            token={getUserDetails?.data?.token}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Index;
