import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../../../../components/Buttons/ButtonSubmit";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function OrganizerProfile({ userId, userData, token }) {
  const key = ["MyUserDetails", userId];
  const mutation = useCustomMutation(key);
  const [inputs, setInputs] = useState({});

  // console.log("inputs...", inputs);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const handleSubmitForm = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `users/updateMe`,
      isJSON = true;
    var formdata = new FormData();
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Profile successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      localStorage.clear();
      localStorage.setItem("authEventKagoUser", JSON.stringify(userData));
    }

    // if (userData?.data?.data?._id) {
    //   const {
    //     firstName,
    //     lastName,
    //     email,
    //     phoneNumber,
    //     organizationName,
    //     organizationType,
    //     organizationAddress,
    //   } = userData?.data?.data;
    //   setInputs({
    //     firstName,
    //     lastName,
    //     email,
    //     phoneNumber,
    //     organizationName,
    //     organizationType,
    //     organizationAddress,
    //   });
    // }
  }, [mutation?.isSuccess]);
  useEffect(() => {
    if (userData?.data?.data?._id) {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        organizationName,
        organizationType,
        organizationAddress,
      } = userData?.data?.data;
      setInputs({
        firstName,
        lastName,
        email,
        phoneNumber,
        organizationName,
        organizationType,
        organizationAddress,
      });
    }
  }, [userData?.data?.token]);

  console.log("...me...", JSON.stringify(mutation?.isSuccess, null, 2));
  console.log("Update...me...", JSON.stringify(userData, null, 2));

  return (
    <div className="w-100 d-flex justify-content-center mt-3">
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row>
          <Col md={6}>
            <label className="event__input__form__Creation">First name </label>
            <input
              type="text"
              name="firstName"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.firstName}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={6}>
            <label className="event__input__form__Creation">Last name </label>
            <input
              type="text"
              name="lastName"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.lastName}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={6}>
            <label className="event__input__form__Creation">Email</label>
            <input
              type="email"
              name="email"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.email}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={6}>
            <label>Phone Number</label>
            <div className="phone-input-container">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={inputs.phoneNumber ? inputs.phoneNumber : null}
                onChange={(value) =>
                  setInputs({ ...inputs, phoneNumber: value })
                }
                className="custom-phone-input"
              />
            </div>
          </Col>
          <Col md={6}>
            <label className="event__input__form__Creation">
              Organization Type
            </label>
            <input
              type="text"
              name="organizationType"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.organizationType}
              onChange={handleChange}
              // required
            />
          </Col>

          <Col md={6}>
            <label className="event__input__form__Creation">
              Organization Name
            </label>
            <input
              type="text"
              name="organizationName"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.organizationName}
              onChange={handleChange}
              // required
            />
          </Col>
          {/* <Col md={12}>
            <label className="event__input__form__Creation">Sector</label>
            <input
              type="text"
              name="sector"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.sector}
              onChange={handleChange}
              // required
            />
          </Col> */}
          <div className="d-flex justify-content-center align-item-center">
            <ButtonSubmit
              Title="Update"
              BackgroundColor="#f68a20"
              ColorText="white"
              BorderColor="#f68a20"
              borderRadius={20}
              handleOnclick={handleSubmitForm}
              pending={mutation.isLoading}
            />
          </div>
        </Row>
      </form>
    </div>
  );
}

export default OrganizerProfile;
