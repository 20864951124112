import React from "react";
import "./login-lotties.scss";
import webinar from "../../../assets/images/banners/login/webinar.png";

function Webinar() {
  return (
    <div className="lotties-login-container">
      <div className="lotties-login-container">
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ userSelect: "none" }}
        >
          <div
            className="rounded-4 lotties-login-container-conference"
            style={{ width: "14em", height: "14em" }}
          >
            <img src={webinar} alt="webinar" className="w-100 h-100" />
          </div>
        </div>
        <div className="lotties-login-conference-caption">
          <div className="w-100 d-flex justify-content-center lotties-login-text">
            From Chaos to Clarity:
          </div>
          <div className="w-100 d-flex justify-content-center lotties-login-text">
            Simplify Your Webinars with Kago Events
          </div>
        </div>
      </div>
    </div>
  );
}

export default Webinar;
