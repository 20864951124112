import React from "react";
import Slide from "../../components/Authentication/Slide";
import Form from "../../components/Authentication/ResetForm";

const ResetPassword = () => {
  document.title = "Kago Events| Reset Password";

  return (
    <div className="authentication-container">
      <div className="authentication-container-form">
        <div className="authentication--wrapper">
          <div className="login--slider-container">
            <div className="login--slider-wrapper">
              <Slide />
            </div>
          </div>
          <div
            className="p-2 login--form--slider-container d-flex"
            style={
              {
                // backgroundColor: "purple",
              }
            }
          >
            <div className="login--form--slider-container--first"></div>

            <div
              className="login--form--slider-container--second d-flex align-items-center"
              style={{
                userSelect: "none",
              }}
            >
              <div className="h-100 d-flex align-items-center">
                <div
                  className="w-100"
                  style={{
                    userSelect: "none",
                    // backgroundColor: "yellow",
                  }}
                >
                  <div className="w-100 d-flex justify-content-center ">
                    <Form />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
