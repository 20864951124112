import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonIcon from "../../Buttons/ButtonIcon";
import { FaRegCalendarAlt } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";

const BoothLobbyNav = ({ eventName, boothName, boothID }) => {
  const navigate = useNavigate();
  return (
    <div
      className="w-100 d-flex justify-content-around py-3"
      style={{
        background: "#f2f2f2",
        top: "70px",
        position: "sticky",
        // position: "absolute",
      }}
    >
      <div>
        <ButtonIcon
          Title="Back"
          BackgroundColor="white"
          ColorText="black"
          BorderColor="white"
          borderRadius={20}
          handleOnclick={() => {
            navigate(-1);
          }}
          Icon={<BiArrowBack color="black" size={20} />}
          pending={false}
        />
      </div>
      <div>
        <ButtonIcon
          Title="Join Live session"
          BackgroundColor="#0ac074"
          ColorText="white"
          BorderColor="#0ac074"
          borderRadius={20}
          handleOnclick={() => {
            navigate();
          }}
          // Icon={<BiArrowBack color="white" size={20} />}
          pending={false}
        />
      </div>
      <div>
        <ButtonIcon
          Title="Add Reminder"
          BackgroundColor="#f68a20"
          ColorText="white"
          BorderColor="#f68a20"
          borderRadius={20}
          handleOnclick={() => {}}
          Icon={<FaRegCalendarAlt color="white" size={20} />}
          pending={false}
        />
      </div>
    </div>
  );
};

export default BoothLobbyNav;
