import React from "react";
import { Row, Container } from "reactstrap";
import AllEvents from "./AllEvents";

const All = ({ t, search, isSwitchOn, userRole }) => {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userId = obj?.data?.data?.id;
  const userEmail = obj?.data?.data?.email;
  const token = obj?.data?.token;

  return (
    <React.Fragment>
      <Container>
        <div>
          <Row>
            <AllEvents
              search={search}
              userId={userId}
              userEmail={userEmail}
              token={token}
              t={t}
              isSwitchOn={isSwitchOn}
              userRole={userRole}
            />
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default All;
