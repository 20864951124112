import React from "react";
// import LogoImg from "../../assets/images/Kago-main-logo.jpeg";
import LogoImg from "../../assets/images/login/login-logo.png";

const Logo = () => {
  return (
    <div className="login-form-logo-size">
      <img
        src={LogoImg}
        className="w-100 h-100"
        style={{ objectFit: "cover" }}
      />
    </div>
  );
};

export default Logo;
