import React from "react";
import { Container } from "reactstrap";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "../../components/Common/withRouter";

const Navbar = (props) => {
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const name = obj?.data?.data?.firstName;
  const capitalizedFirstName = name
    ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
    : "";

  const hour = new Date().getHours();
  const date = new Date().getDate();
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  let timeOfDay;

  if (hour >= 5 && hour < 12) {
    timeOfDay = props.t("Good morning");
  } else if (hour >= 12 && hour < 18) {
    timeOfDay = props.t("Good afternoon");
  } else {
    timeOfDay = props.t("Good evening");
  }

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedMonth = monthNames[month];

  return (
    <React.Fragment>
      <div
        className="topnav"
        style={{
          backgroundColor: "white",
          color: "black",
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "#e9e9e9",
        }}
      >
        <Container fluid>
          <div className="d-flex justify-content-between pt-4">
            <div className="d-flex align-items-end">
              <p className="fw-bold font-size-14">
                {timeOfDay}, {capitalizedFirstName}
              </p>
            </div>
            <div className="d-flex align-items-end">
              <p className="fw-bold font-size-14">
                {`${date} ${formattedMonth} ${year}`}
              </p>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
