import React, { useState } from "react";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import ViewRegistrationForm from "./ViewRegistrationForm";
import CreateRegistration from "./CreateRegistration";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";

const RegistrationTab = ({ userID, token, eventID, eventDetails }) => {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

  const toggleSaveChanges = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };
  const {
    data: getMyEventForm,
    error: errorGetMyEventForm,
    isLoading: isLoadingGetMyEventForm,
  } = useCustomFecthQuery(
    ["eventForm", eventID],
    `${process.env.REACT_APP_API}event-form/${eventID}`,
    null,
    { enabled: !!eventID }
  );

  const isDataEmpty =
    !Array.isArray(getMyEventForm?.data) || getMyEventForm?.data?.length === 0;

  return (
    <React.Fragment>
      {isLoadingGetMyEventForm ? null : (
        <div
          className="w-100 mb-5 d-flex justify-content-center"
          style={{ paddingTop: "5%" }}
        >
          {isDataEmpty ? (
            <>
              <div
                className="d-flex justify-content-center align-items-center mt-3 rounded-3 px-4 py-4 "
                style={{
                  width: "75%",
                  margin: "0px auto",
                  backgroundColor: "white",
                }}
              >
                <ButtonSubmit
                  Title="Create Form"
                  BackgroundColor="#f68a20"
                  ColorText="#fff"
                  BorderColor="#f68a20"
                  borderRadius={20}
                  pending={false}
                  handleOnclick={() => toggleSaveChanges()}
                />
              </div>
            </>
          ) : (
            <>
              <ViewRegistrationForm
                userID={userID}
                token={token}
                eventID={eventID}
                formContent={getMyEventForm?.data}
              />
            </>
          )}
        </div>
      )}

      <OffCanvas
        isOffcanvasOpen={isOffcanvasOpen}
        title="Create Registration form"
        body={
          <CreateRegistration
            userID={userID}
            token={token}
            eventID={eventID}
            setIsOffcanvasOpen={setIsOffcanvasOpen}
          />
        }
        toggleSaveChanges={() => toggleSaveChanges()}
        directionOffCanvas="end"
        widthOffCanvas="100%"
      />
    </React.Fragment>
  );
};

export default RegistrationTab;
