import React from "react";
import { useNavigate } from "react-router-dom";
import { Col } from "reactstrap";
import ButtonSubmit from "../../../components/Buttons/ButtonStyle";
import ImagePlaceHolder from "../../../assets/images/placeholder/ImagePlaceHolder.png";
import LoaderEventItem from "../../../components/Loader/LoaderEventItem";

const AllEventPersonnelItems = ({
  userEmail,
  filteredEvents,
  isLoadingGetEvents,
}) => {
  const Navigate = useNavigate();
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // const checkForEmail = (personnelArray, targetEmail) => {
  //   return personnelArray?.some((person) => person?.email === targetEmail);
  // };

  const checkForRole = (personnelArray, targetEmail) => {
    let roles = {
      isOrganizer: false,
      isCoOrganizer: false,
      isMarketing: false,
      isSales: false,
      isFinance: false,
      isHost: false,
      isCoordinator: false,
      isExhibitor: false,
      isExhibitorAssistance: false,
      isSpeaker: false,
      isSecurity: false,
    };

    personnelArray?.forEach((person) => {
      if (person?.email === targetEmail) {
        if (person?.role === "event_organizer") {
          roles.isOrganizer = true;
        } else if (person?.role === "co_event_organizer") {
          roles.isCoOrganizer = true;
        } else if (person?.role === "event_org_marketing") {
          roles.isMarketing = true;
        } else if (person?.role === "event_org_sales") {
          roles.isSales = true;
        } else if (person?.role === "event_org_finance") {
          roles.isFinance = true;
        } else if (person?.role === "host") {
          roles.isHost = true;
        } else if (person?.role === "session_coordinator") {
          roles.isCoordinator = true;
        } else if (person?.role === "exhibitor") {
          roles.isExhibitor = true;
        } else if (person?.role === "exhibitor_assistant") {
          roles.isExhibitorAssistance = true;
        } else if (person?.role === "speaker") {
          roles.isSpeaker = true;
        } else if (person?.role === "securityGuard") {
          roles.isSecurity = true;
        }
      }
    });

    return roles;
  };

  const handleAttendeeEnterEvent = (eventName, evID) => {
    Navigate(`/${eventName?.replace(/ /g, "-")}/event-landing/${evID}`);
  };

  const handleEditEvent = (eventName, evID) => {
    Navigate(`/${eventName?.replace(/ /g, "-")}/set/${evID}`);
  };

  const handleSetBooth = (eventName, evID) => {
    Navigate(`/${eventName?.replace(/ /g, "-")}/set-booth/${evID}`);
  };

  if (isLoadingGetEvents) {
    return <LoaderEventItem />;
  }

  return (
    <>
      {filteredEvents?.map((item, index) => {
        // Format the event's start date
        let dateValue = new Date(item.startDate);
        let day = dayNames[dateValue.getUTCDay()];
        let month = monthNames[dateValue.getUTCMonth()];
        let year = dateValue.getUTCFullYear();
        let date = dateValue.getUTCDate();
        let formattedDate = `${day} ${date} ${month} ${year}`;

        // const isAdmin = checkForEmail(item?.personnel, userEmail);
        const {
          isOrganizer,
          isCoOrganizer,
          isMarketing,
          isSales,
          isFinance,
          isCoordinator,
          isExhibitor,
          isExhibitorAssistance,
          isSpeaker,
          isHost,
          isSecurity,
        } = checkForRole(item?.personnel, userEmail);

        return (
          <Col xs={12} sm={12} md={12} lg={12} key={index}>
            <div className="d-flex p-3 mb-4 shadow-sm bg-white card-brd myEvent-card-container">
              <div className="d-flex align-items-center" style={{ flex: 0.4 }}>
                <div className="MyEvent_Parent_div d-flex pt-3">
                  <div className="MyEvent_image_div">
                    <img
                      src={
                        item?.bannerImage ? item?.bannerImage : ImagePlaceHolder
                      }
                      width="100%"
                      height="100%"
                      className="card-brd"
                      alt="event"
                    />
                  </div>
                </div>
              </div>
              <div className="w-100 mx-2">
                <div
                  style={{ fontSize: 16, fontWeight: "600", color: "black" }}
                >
                  {item?.eventName}
                </div>
                <div className="mt-2" style={{ fontSize: 13 }}>
                  {item?.eventDescription && item?.eventDescription.length > 35
                    ? `${item?.eventDescription.substring(0, 200)}...`
                    : item?.eventDescription}
                </div>
                <div
                  style={{ fontSize: 14, fontWeight: "600", color: "black" }}
                >
                  {formattedDate} | {`${item.startTime} - ${item.endTime} SAST`}
                </div>
                <div className="mt-2 mb-1" style={{ fontSize: 13 }}>
                  <ButtonSubmit
                    Title="Visit Event"
                    ColorText="#F68A20"
                    BorderColor="#F68A20"
                    BackgroundColor="white"
                    borderRadius="25px"
                    handleOnclick={() =>
                      handleAttendeeEnterEvent(item?.eventName, item?._id)
                    }
                  />
                </div>
                {isOrganizer ||
                isCoOrganizer ||
                isMarketing ||
                isSales ||
                isFinance ||
                isHost ||
                isCoordinator ? (
                  <div
                    style={{
                      fontSize: 14,
                      textDecoration: "underline",
                      color: "#0e35dd",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEditEvent(item?.eventName, item?._id)}
                  >
                    Edit Event
                  </div>
                ) : null}
                {isExhibitor || isExhibitorAssistance ? (
                  <div
                    style={{
                      fontSize: 14,
                      textDecoration: "underline",
                      color: "#0e35dd",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSetBooth(item?.eventName, item?._id)}
                  >
                    Set Up booth
                  </div>
                ) : null}
              </div>
            </div>
          </Col>
        );
      })}
    </>
  );
};

export default AllEventPersonnelItems;
