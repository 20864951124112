import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useCustomFecthQuery from "../../../../Hooks/useCustomFecthQuery";
import boothbgImg from "../../../../assets/images/background/booth-bg.png";
import "./.receptionLobby.scss";
import boothOne from "../../../../assets/images/booths/1.png";
import boothTwo from "../../../../assets/images/booths/2.png";
import boothThree from "../../../../assets/images/booths/3.png";
import boothFour from "../../../../assets/images/booths/4.png";

const ExhibitorBooth = ({ userEmail, eventName, eventID, token }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const toggleEnterBooth = (boothID, boothName) => {
    navigate(`/${eventName}/booth/${boothName?.replace(/ /g, "-")}/${boothID}`);
  };

  const {
    data: getBoothDetails,
    error: errorGetBoothDetails,
    isLoading: isLoadingGetBoothDetails,
  } = useCustomFecthQuery(
    ["myBooth", eventID],
    `${process.env.REACT_APP_API}booths/?email=${userEmail}&eventId=${eventID}`,
    token,
    { enabled: !!eventID }
  );

  return (
    <div className="btn-booth-booths-container">
      <div
        style={{
          backgroundImage: `url(${boothbgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <div className="">
          <div className="booth-reception-container">
            <div className="booth-reception-wrapper">
              <div className="booth-reception-wrapper-img">
                <img
                  src={
                    getBoothDetails?.data?.boothImage === "1"
                      ? boothOne
                      : getBoothDetails?.data?.boothImage === "2"
                      ? boothTwo
                      : getBoothDetails?.data?.boothImage === "3"
                      ? boothThree
                      : getBoothDetails?.data?.boothImage === "4"
                      ? boothFour
                      : null
                  }
                  alt="booth-image"
                  className="w-100 h-100"
                />
                <div className="company-exhibition-lobby-exhibitor">
                  <img
                    src={getBoothDetails?.data?.boothLogo}
                    alt="company-logo"
                  />
                </div>
                <div className="company-name-lobby">
                  {getBoothDetails?.data?.boothName}
                </div>

                <button
                  className="btn-exhibition-lobby btn-enter-booth"
                  onClick={() =>
                    toggleEnterBooth(
                      getBoothDetails?.data?._id,
                      getBoothDetails?.data?.boothName
                    )
                  }
                >
                  Enter Booth
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitorBooth;
