import React, { useState } from "react";
import { Row, Col } from "reactstrap";
// import ButtonSubmit from "../../Buttons/ButtonSubmit";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function CheckoutPackageForm({ packageData }) {
  const [inputs, setInputs] = useState({
    name_first: "",
    name_last: "",
    phoneNumber: "",
    address: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  return (
    <div className="w-100 d-flex justify-content-center">
      <form noValidate autoComplete="off" id="checkout-container">
        <Row>
          {/* First Name Field */}
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>First Name
            </label>
            <input
              type="text"
              name="name_first"
              value={packageData?.lead?.firstName}
              placeholder="Enter First Name"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control"
              disabled
            />
          </Col>

          {/* Last Name Field */}
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Last Name
            </label>
            <input
              type="text"
              name="name_last"
              value={packageData?.lead?.lastName}
              placeholder="Enter Last Name"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control"
              disabled
            />
          </Col>

          {/* Phone Number Field */}
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Phone Number
            </label>
            <div className="phone-input-container">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={packageData?.lead?.phoneNumber}
                onChange={(value) =>
                  setInputs({
                    ...inputs,
                    phoneNumber: packageData?.lead?.phoneNumber,
                  })
                }
                className="custom-phone-input"
              />
            </div>
          </Col>

          {/* Physical Address Field */}
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Email
            </label>
            <input
              type="email"
              name="email_address"
              value={packageData?.lead?.email}
              placeholder="Enter Email Address"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control"
              disabled
            />
          </Col>

          {/* <div className="w-100 d-flex justify-content-center">
            <ButtonSubmit
              Title="Next"
              ColorText="white"
              BorderColor="#000"
              BackgroundColor="#000"
              borderRadius="25px"
              handleOnclick={handleNext}
            />
          </div> */}
        </Row>
      </form>
    </div>
  );
}

export default CheckoutPackageForm;
