import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import usePost from "../../../../Hooks/usePost";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../../../../components/Buttons/ButtonSubmit";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function ParticipantProfile({ userId, userData, token }) {
  const key = ["MyUserDetails", userId];
  const mutation = useCustomMutation(key);
  const { execute, pending, data, status } = usePost();
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `users/updateMe`,
      isJSON = true;
    var formdata = new FormData();
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Profile successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      if (userData?.data?.data?._id) {
        localStorage.clear();
        localStorage.setItem("authEventKagoUser", JSON.stringify(userData));
      }
    }
    if (userData?.data?.data?._id) {
      const { firstName, lastName, email, phoneNumber } = userData?.data?.data;
      setInputs({
        firstName,
        lastName,
        email,
        phoneNumber,
      });
    }
  }, [mutation?.isSuccess, userData?.data?.data?._id]);

  return (
    <div className="w-100 d-flex justify-content-center mt-3">
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row>
          <Col md={6}>
            <label className="event__input__form__Creation">First name </label>
            <input
              type="text"
              name="firstName"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.firstName}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={6}>
            <label className="event__input__form__Creation">Last name</label>
            <input
              type="text"
              name="lastName"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.lastName}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={6}>
            <label className="event__input__form__Creation">Email</label>
            <input
              type="email"
              name="email"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.email}
              onChange={handleChange}
              disabled
            />
          </Col>
          <Col md={6}>
            <label>Phone Number</label>
            <div className="phone-input-container">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={inputs.phoneNumber ? inputs.phoneNumber : null}
                onChange={(value) =>
                  setInputs({ ...inputs, phoneNumber: value })
                }
                className="custom-phone-input"
              />
            </div>
          </Col>

          <div className="d-flex justify-content-center align-item-center">
            <ButtonSubmit
              Title="Update"
              BackgroundColor="#f68a20"
              ColorText="white"
              BorderColor="#f68a20"
              borderRadius={20}
              handleOnclick={handleSubmitForm}
              pending={pending}
            />
          </div>
        </Row>
      </form>
    </div>
  );
}

export default ParticipantProfile;
