import React from "react";
import CryptoJS from "crypto-js";
import { encryptDataInOneLine } from "../../utils/encryptDataInOneLine";
import ButtonGradient from "../../components/Buttons/ButtonGradient";

function generateSignature(data, passPhrase = null) {
  let pfOutput = "";
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] !== "") {
        pfOutput += `${key}=${encodeURIComponent(data[key]?.trim())?.replace(
          /%20/g,
          "+"
        )}&`;
      }
    }
  }

  let getString = pfOutput.slice(0, -1);
  if (passPhrase !== null) {
    getString += `&passphrase=${encodeURIComponent(passPhrase?.trim())?.replace(
      /%20/g,
      "+"
    )}`;
  }

  return CryptoJS.MD5(getString).toString(CryptoJS.enc.Hex);
}

const PackageDetails = ({ packageData }) => {
  // console.log("....mechantInfo....", mechantInfo?.merchantId);

  const handlePayNow = () => {
    // const encodedNameFirst = encodeURIComponent(packageData?.lead?.firstName);
    // const encodedNameLast = encodeURIComponent(packageData?.lead?.lastName);
    // const encodedEmail = encodeURIComponent(packageData?.lead?.email);
    // const encodedPhone = encodeURIComponent(packageData?.lead?.phoneNumber);

    const queryString = `name_first=${packageData?.lead?.firstName}&name_last=${packageData?.lead?.lastName}&email=${packageData?.lead?.email}&phone=${packageData?.lead?.phoneNumber}&packageId=${packageData?._id}`;
    const secretKey = "14102024@Kago";

    const encryptedQueryString = encryptDataInOneLine(queryString, secretKey);

    const returnUrl = `https://events.kago.digital/confirmations?data=${encryptedQueryString}`;

    const myData = {
      // merchant_id: process.env.REACT_APP_MechantID,
      merchant_id: "10034886",
      // merchant_key: process.env.REACT_APP_MechantKey,
      merchant_key: "hnsqkza5g704p",
      return_url: returnUrl,
      cancel_url: `https://events.kago.digital/payment/cancel`,
      notify_url: `https://events.kago.digital/payment/notification`,
      name_first: packageData?.lead?.firstName,
      name_last: packageData?.lead?.lastName,
      email_address: packageData?.lead?.email,
      m_payment_id: packageData?._id,
      amount: `${packageData?.price}`,
      item_name: packageData?.service,
    };

    // const myPassphrase = process.env.REACT_APP_PassPhrase;
    const myPassphrase = "Kago-testing-environemnt";

    myData.signature = generateSignature(myData, myPassphrase);

    // console.log("signature...", myData?.signature);

    const form = document.createElement("form");
    // form.action = `${process.env.REACT_APP_PayFastUrl}`;
    form.action = "https://sandbox.payfast.co.za/eng/process";
    form.method = "post";
    form.id = "payment-form";

    for (const [key, value] of Object.entries(myData)) {
      if (value !== "") {
        const hiddenInput = document.createElement("input");
        hiddenInput.type = "hidden";
        hiddenInput.name = key;
        hiddenInput.value = value?.trim();
        form.appendChild(hiddenInput);
      }
    }

    const checkoutContainer = document.getElementById("checkout-container");

    // Check if checkoutContainer exists before appending the form
    if (checkoutContainer) {
      checkoutContainer.appendChild(form);
      form.submit();
    } else {
      console.error("Checkout container not found.");
    }
  };

  return (
    <div className="w-100 checkout" id="checkout-container">
      <div className="order"></div>
      <div className="h4">Payment</div>
      <div
        className="mt-2 text-primary"
        style={{ fontSize: 15, fontWeight: "bolder" }}
      >
        {packageData?.service}
      </div>
      {/* {ticket?.ticketDetail?.ticketDescription && (
        <>
          <div style={{ fontSize: 18, fontWeight: "bold" }}>Description</div>
          <div style={{ fontSize: 13 }}>
            {ticket?.ticketDetail?.ticketDescription}
          </div>
        </>
      )} */}
      <div
        className="mt-2 mb-2 text-primary"
        style={{ fontSize: 22, fontWeight: "bolder" }}
      >
        R {packageData?.price}
      </div>

      {/* {ticket?.ticketTerms && (
        <>
          <div style={{ fontSize: 18, fontWeight: "bold" }}>Terms</div>
          <div style={{ fontSize: 13 }}>{ticket?.ticketTerms}</div>
        </>
      )} */}

      <ButtonGradient
        type="button"
        Title="Pay Now"
        leftColor="#f7983c"
        rightColor="#ff7c6e"
        ColorText="white"
        BorderColor="transparent"
        borderRadius={50}
        handleOnclick={handlePayNow}
      />
    </div>
  );
};

export default PackageDetails;
