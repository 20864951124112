import React from "react";
import { Card, CardBody } from "reactstrap";
import { FaRegClock } from "react-icons/fa6";
import { GrMicrophone } from "react-icons/gr";
import { IoHandLeftOutline } from "react-icons/io5";
import "./houseRules.scss";
const HouseRules = () => {
  const rules = [
    {
      icon: <GrMicrophone size={28} color="#000" />,
      text: "Check your mute/unmute button",
    },
    {
      icon: <FaRegClock size={28} />,
      text: "Respect everyone's time by keeping to the schedule",
    },
    {
      icon: <IoHandLeftOutline size={28} />,
      text: "Raise Your Hand Before You Can Speak",
    },
  ];

  return (
    <div className="house-rules-container">
      {rules.map((rule, index) => (
        <div className="rule-card" key={index}>
          <div className="icon">{rule.icon}</div>
          <div className="houseDivider"></div>
          <div className="text">{rule.text}</div>
        </div>
      ))}
    </div>
  );
};

export default HouseRules;
