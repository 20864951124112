import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import MerchantForm from "./components/Profile/MerchantForm";

import withRouter from "../../components/Common/withRouter";

import Breadcrumb from "../../components/Common/Breadcrumb";

const Merchant = () => {
  document.title = " Kago Events | Payment";

  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userData = obj?.data;
  const token = obj?.data?.token;

  // console.log("obj....,", userDetails);
  // const getOffsetTop = (element) => {
  //   let offsetTop = 0;
  //   while (element) {
  //     offsetTop += element.offsetTop;
  //     element = element.offsetParent;
  //   }
  //   return offsetTop;
  // };

  // const scrollToElement = (elementId) => {
  //   const element = document.getElementById(elementId);
  //   if (element) {
  //     const offsetTop = getOffsetTop(element);
  //     window.scrollTo({
  //       top: offsetTop,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  return (
    <React.Fragment>
      <div className="page-content" style={{ userSelect: "none" }}>
        <Container fluid>
          <Breadcrumb title="Kago Events" breadcrumbItem="Ticket Payment" />
          <Row>
            <Col md={2}></Col>
            <Col md={8} id="profile">
              <Card>
                <CardBody
                  id="password"
                  className="w-100 p-4 profile-container"
                  style={{ userSelect: "none" }}
                >
                  <div className="profile-form-title">
                    PayFast Merchandise Details
                  </div>
                  <MerchantForm userData={userData} token={token} />
                </CardBody>
              </Card>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Merchant);
