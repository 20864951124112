import React from "react";
import SimpleBar from "simplebar-react";
import { detectDeviceType } from "../../../utils/deviceType";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
// import About from "./One/About";
import Schedule from "./One/Schedule";
import EventPlaceholder from "../../../assets/images/placeholder/EventPplaceholder.png";

const EventConferenceOne = ({ userEmail, token, eventID, eventName }) => {
  const deviceType = detectDeviceType();
  const {
    data: getMyEventDetail,
    error: errorGetMyEventDetail,
    isLoading: isLoadingGetMyEventDetail,
  } = useCustomFecthQuery(
    ["myEventDetails", eventID],
    `${process.env.REACT_APP_API}events/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  const {
    data: getMySessions,
    error: errorGetMySessions,
    isLoading: isLoadingGetMySessions,
  } = useCustomFecthQuery(
    ["mySessions", eventID],
    `${process.env.REACT_APP_API}sessions/event/${eventID}`,
    token,
    { enabled: !!eventID }
  );

  return (
    <React.Fragment>
      <div className="w-100">
        <div className="p-5">
          <div className="p-2">
            <div className="mt-3"></div>
          </div>
        </div>

        <div className="w-100">
          <SimpleBar style={{ height: "75vh" }}>
            <div
              className="w-100 mt-2"
              style={{
                position: "relative",
                height: 400,
                backgroundColor: "white",
                borderRadius: 10,
              }}
            >
              <img
                src={
                  getMyEventDetail?.data?.bannerImage
                    ? getMyEventDetail?.data?.bannerImage
                    : EventPlaceholder
                }
                alt="Event Banner"
                style={{ objectFit: "contain", borderRadius: 10 }}
                className="w-100 h-100"
              />
            </div>
            <div
              className="w-100 pt-3 pb-3"
              style={{ backgroundColor: "white" }}
            >
              {/* <About
                eventDetails={getMyEventDetail?.data}
                userEmail={userEmail}
              /> */}
            </div>
            {getMySessions?.status === "ok" &&
            getMySessions?.data?.length === 0 ? null : (
              <div className="mt-4">
                <Schedule
                  eventDetails={getMyEventDetail?.data}
                  token={token}
                  eventID={eventID}
                  eventName={eventName}
                />
              </div>
            )}
          </SimpleBar>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventConferenceOne;
