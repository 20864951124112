import React, { useState } from "react";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import { isBrowser } from "react-device-detect";
import SwitchButton from "../../../components/Buttons/SwitchButton";
import CreateCommunity from "../../../components/Community/AddCommunity";
import All from "./All";
import { AiOutlineSearch } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory
import "../../../assets/scss/custom/pages/_dashboard.scss";
import PropTypes from "prop-types";
import EventFilter from "./EventFilter";

const ShowEvents = ({ t, userId, userEmail, token, userRole }) => {
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [eventType, setEventType] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [location, setLocation] = useState("");
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  const handleSearch = (event) => setSearch(event.target.value);

  const handleApplyFilters = () => {
    if (startDate && endDate && startDate > endDate) {
      alert("Start date cannot be later than end date.");
      return;
    }
    // console.log("Applying filters:", {
    //   search,
    //   startDate,
    //   endDate,
    //   eventType,
    //   paymentType,
    //   location,
    // });
  };

  const handleCreateCommunity = () => setModal(!modal);

  // console.log("userRole...", userRole);

  if (isBrowser) {
    return (
      <div className="dashboard-container">
        <Row>
          {/* Filter Panel */}
          <Col xs={12} sm={12} md={4} lg={3} className="filter-panel">
            <EventFilter />
          </Col>

          {/* Events Display Section */}
          <Col xs={12} sm={12} md={8} lg={9}>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h4 className="m-0">Upcoming Events</h4>
              <div className="d-flex justify-content-between align-items-center">
                <span
                  style={{ fontWeight: "bolder", fontSize: 16, marginRight: 5 }}
                >
                  Switch To Community
                </span>
                <SwitchButton
                  isOn={isSwitchOn}
                  handleToggle={() => setIsSwitchOn(!isSwitchOn)}
                />
              </div>
            </div>

            {/* Buttons Section */}
            <div className="d-flex justify-content-between mb-4">
              {userRole?.includes("event_organizer") ? (
                <div className="d-flex align-items-center">
                  {/* <span className="mr-2">Ready to create an event?</span> */}
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      navigate("/create-event");
                    }}
                  >
                    Create Event
                  </button>
                </div>
              ) : null}

              {/* <div className="d-flex align-items-center">
                <span className="mr-2">Get a quotation for your event?</span>
                <button
                  className="btn btn-info"
                  onClick={() => navigate("/request-demo")}
                >
                  Request Demo
                </button>
              </div> */}
            </div>

            <All t={t} search={search} isSwitchOn={isSwitchOn} />
          </Col>
        </Row>

        {/* Community Creation Modal */}
        <Modal isOpen={modal} toggle={handleCreateCommunity} fullscreen>
          <ModalBody>
            <div className="d-flex justify-content-end">
              <div className="close-button" onClick={handleCreateCommunity}>
                <MdClose size={30} />
              </div>
            </div>
            <div className="w-100 d-flex justify-content-center align-items-center">
              <CreateCommunity
                userId={userId}
                userEmail={userEmail}
                token={token}
                setModal={setModal}
              />
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }

  return null;
};

// Define prop types for validation
ShowEvents.propTypes = {
  t: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default ShowEvents;
