import React from "react";
import RequestForm from "./RequestForm";
const UpgradeForm = ({ setIsUpgradeEvent, userDetails, userID, token }) => {
  return (
    <div className="w-100 d-flex justify-content-center">
      <RequestForm
        setIsUpgradeEvent={setIsUpgradeEvent}
        userDetails={userDetails}
        userID={userID}
        token={token}
      />
    </div>
  );
};

export default UpgradeForm;
