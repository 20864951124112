import React from "react";
import { useParams } from "react-router-dom";
import EventExhibitionOne from "../../components/Common/EventLobby/EventExhibitionOne";

const EventExhibitionLobby = () => {
  document.title = "Kago Events | Event Reception";
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userEmail = obj?.data?.data?.email;
  const token = obj?.data?.token;
  const params = useParams();
  const eventID = params.eventID;
  const eventName = params.eventName;

  return (
    <React.Fragment>
      <EventExhibitionOne
        userEmail={userEmail}
        token={token}
        eventID={eventID}
        eventName={eventName}
      />
    </React.Fragment>
  );
};

export default EventExhibitionLobby;
