import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import EditProfilePicture from "../../../../components/Profile/EditProfilePicture";
import ButtonSubmit from "../../../../components/Buttons/ButtonSubmit";

const Profile = ({ userId, isEventOrganizer, userData, token }) => {
  const key = ["MyUserDetails", userId];
  const mutation = useCustomMutation(key);

  const [showImage, setShowImage] = useState(null);
  const [uploadImg, setUploadImg] = useState(null);
  const [inputs, setInputs] = useState({});

  const SaveBtn = () => {
    const Method = "PATCH",
      url = `users/updateMe`,
      isJSON = true;
    var formdata = new FormData();
    if (uploadImg) {
      formdata.append("photo", uploadImg);
    }

    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Profile successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      if (userData?.data?.data?._id) {
        localStorage.clear();
        localStorage.setItem("authEventKagoUser", JSON.stringify(userData));
      }
    }
  }, [mutation?.isSuccess]);

  useEffect(() => {
    if (userData?.data?.data?._id) {
      const { photo, firstName, lastName } = userData?.data?.data;
      setShowImage(photo);
      setInputs({
        firstName,
        lastName,
      });
    }
  }, [userData?.data?.token]);

  return (
    <div className="d-flex justify-content-between">
      <div className="w-100 d-flex">
        <div className="profile-img-container">
          <EditProfilePicture
            showImage={showImage}
            setShowImage={setShowImage}
            setUploadImg={setUploadImg}
            iconSize={80}
            iconColor="white"
            imgHeight={80}
            imgWidth={80}
            imgBorder={10}
            bgColor="#F68A20"
          />
        </div>

        <div className="d-flex justify-content-between align-items-center mx-3 profile-container-main">
          <div>
            <div className="profile-container-Title">
              {inputs?.firstName} {inputs?.lastName}
            </div>
            <div className="profile-container-subtitle">
              {isEventOrganizer ? "Event Organizer" : "Participant"}
            </div>
          </div>
          <ButtonSubmit
            Title="Save Change"
            BackgroundColor="#f68a20"
            ColorText="white"
            BorderColor="#f68a20"
            borderRadius={20}
            handleOnclick={SaveBtn}
            pending={mutation.isLoading}
          />
        </div>
      </div>
    </div>
  );
};
export default Profile;
