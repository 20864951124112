import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import Select from "react-select";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../../../Buttons/ButtonSubmit";
import SwitchButton from "../../../Buttons/SwitchButton";
import { countryList } from "../../../Countries/countries";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function AddExhibitor({ token, eventID, setIsOffcanvasOpen, eventFormat }) {
  const [isLiveSession, setIsLiveSession] = useState(false);
  const [inputs, setInputs] = useState({});
  const key = ["myExhibitors", eventID];
  const mutation = useCustomMutation(key);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };
  const toggleLiveSession = () => {
    setIsLiveSession(!isLiveSession);
  };

  const toggleSaveChanges = (e) => {
    e.preventDefault();
    const Method = "POST",
      url = `exhibitors/${eventID}`;
    const raw = JSON.stringify({
      boothNumber: inputs.boothNumber,
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      email: inputs.email,
      phoneNumber: inputs.phoneNumber,
      organization: inputs.organization,
      city: inputs.city,
      liveBroadcast: isLiveSession,
      duration: inputs.duration ? inputs.duration : "0",
    });

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Exhibitor successfully invited",
      token: token,
      isJSON: null,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (mutation?.isSuccess) {
      setIsOffcanvasOpen(false);
    }
  }, [mutation?.isSuccess]);

  // const customSelectStyles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     backgroundColor: state.isFocused ? "#f68a20" : "white",
  //     color: state.isFocused ? "white" : "black",
  //   }),
  //   control: (provided) => ({
  //     ...provided,
  //     borderColor: "#ccc",
  //     "&:hover": {
  //       borderColor: "#f68a20",
  //     },
  //   }),
  // };

  console.log("Inputs...", JSON.stringify(inputs, null, 2));

  return (
    <div className="w-100 d-flex justify-content-center p-3">
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={toggleSaveChanges}
      >
        <Row>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Booth Reference
            </label>
            <input
              type="text"
              name="boothNumber"
              value={inputs?.boothNumber}
              placeholder="Enter Booth Reference"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            {eventFormat === "Virtual" && (
              <div className="w-100">
                <label>
                  <span className="text-danger">*</span>Booth Duration
                </label>
                <input
                  type="number"
                  name="duration"
                  value={inputs?.duration}
                  placeholder="1 - 120 minute(s)"
                  onChange={handleChange}
                  style={{ borderRadius: 7 }}
                  className="mb-3 form-control fromInput"
                  min="1"
                  max="120"
                  required
                />
              </div>
            )}
          </Col>

          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>First name
            </label>
            <input
              type="text"
              name="firstName"
              value={inputs?.firstName}
              placeholder="Enter First name"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Last name
            </label>
            <input
              type="text"
              name="lastName"
              value={inputs?.lastName}
              placeholder="Enter Last name"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            {" "}
            <label>
              <span className="text-danger">*</span>Email
            </label>
            <input
              type="email"
              name="email"
              value={inputs?.email}
              placeholder="Enter email"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>

          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Organization Name
            </label>
            <input
              type="text"
              name="organization"
              value={inputs?.organization}
              placeholder="Enter organization name"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>Cellphone Number
            </label>
            <div className="phone-input-container">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={inputs.phoneNumber ? inputs.phoneNumber : null}
                onChange={(value) =>
                  setInputs({ ...inputs, phoneNumber: value })
                }
                className="custom-phone-input"
              />
            </div>
          </Col>
          <Col md={6} lg={6}>
            <label>
              <span className="text-danger">*</span>City
            </label>
            <input
              type="text"
              name="city"
              placeholder="Enter city"
              value={inputs?.city}
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              min="1"
              max="120"
              required
            />
          </Col>

          {/* <Col md={6} lg={6}>
            <label className="">Country</label>
            <Select
              options={countryList.map((country) => ({
                value: country,
                label: country,
              }))}
              styles={customSelectStyles}
              placeholder="Select..."
              onChange={(option) =>
                setInputs({ ...inputs, organizationCountry: option.value })
              }
            />
          </Col> */}
          {eventFormat === "Hybrid" && (
            <Col md={12} lg={12}>
              <div className="d-flex mt-3">
                <SwitchButton
                  isOn={isLiveSession}
                  handleToggle={() => toggleLiveSession()}
                />

                <div className="mx-2">
                  <p className="text-primary fw-bolder">Set Live Session</p>
                </div>
              </div>
            </Col>
          )}

          {isLiveSession && (
            <Col md={6} lg={6}>
              <label>
                <span className="text-danger">*</span>Booth Duration
              </label>
              <input
                type="number"
                name="duration"
                value={inputs?.duration}
                placeholder="1 - 120 minute(s)"
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                min="1"
                max="120"
                required
              />
            </Col>
          )}

          <div className="mb-3"></div>
          <div className="w-100 d-flex justify-content-center">
            <ButtonSubmit
              Title="Submit"
              ColorText="white"
              BorderColor="#F68A20"
              BackgroundColor="#F68A20"
              borderRadius="25px"
              handleOnclick={toggleSaveChanges}
              pending={mutation?.isLoading}
            />
          </div>
        </Row>
      </form>
    </div>
  );
}

export default AddExhibitor;
