import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import useCustomMutation from "../../../Hooks/useCustomMutation";
// import ButtonSubmit from "../../Buttons/ButtonSubmit";
import SmBtn from "../../Buttons/SmBtn";
import ModalDelete from "../../Modal/ModalDelete";
import DeleteForm from "../../EventSetUp/EventBranding/DeleteEvent";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CiLink } from "react-icons/ci";

const EventActions = (props) => {
  // const [eventState, setEventState] = useState(null);
  const Navigate = useNavigate();
  const eventID = props?.eventID;
  const token = props?.token;
  const params = useParams();
  const eventName = params?.eventName;
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isToggleShare, setIsToggleShare] = useState(false);
  const userEmail = props?.userEmail;
  const getEventDetails = props?.getEventDetails;
  var state = getEventDetails?.data?.state;
  const handleToggleDelete = () => {
    setIsToggleDelete(!isToggleDelete);
  };
  const key = ["Events", userEmail];
  const keyPublish = ["EventDetails", eventID];

  // console.log("getEventDetails....", getEventDetails?.data?.eventType);

  const mutationDelete = useCustomMutation(key);
  const mutationPublish = useCustomMutation(keyPublish);

  const DeleteEvent = () => {
    const Method = "DELETE",
      url = `events/${eventID}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Event successfully deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };
  useEffect(() => {
    if (mutationDelete?.isSuccess) {
      Navigate("/dashboard");
    }
  }, [mutationDelete?.isSuccess]);

  const handleEventState = (eventStatus) => {
    // event.preventDefault();
    const Method = "PATCH",
      url = `events/${eventID}`,
      isJSON = true;
    var formdata = new FormData();
    if (eventStatus) formdata.append("state", eventStatus);
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Event Status successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutationPublish.mutate(data);
  };
  const handleShareEvent = () => {
    setIsToggleShare(!isToggleShare);
  };
  // console.log("eventType.....", getEventDetails?.data?.eventType);
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: 10,
          gap: 5,
        }}
      >
        <SmBtn
          Title="Share Event"
          BackgroundColor="#fff"
          ColorText="#0ac074"
          BorderColor="#0ac074"
          borderRadius={25}
          handleOnclick={handleShareEvent}
          // pending={mutationPublish?.isLoading}
        />
        {state === "Not Published" ? (
          <SmBtn
            Title="Publish Event"
            BackgroundColor="#fff"
            ColorText="#0ac074"
            BorderColor="#0ac074"
            borderRadius={25}
            handleOnclick={() => {
              handleEventState("Published");
            }}
            pending={mutationPublish?.isLoading}
          />
        ) : null}

        {state === "Published" ? (
          <SmBtn
            Title="Unpublish Event"
            BackgroundColor="#fff"
            ColorText="#ff0000"
            BorderColor="#ff0000"
            borderRadius={25}
            handleOnclick={() => {
              handleEventState("Not Published");
            }}
            pending={mutationPublish?.isLoading}
          />
        ) : null}

        <SmBtn
          Title="Enter Event"
          BackgroundColor="white"
          ColorText="#f68a20"
          BorderColor="#f68a20"
          borderRadius={25}
          handleOnclick={() => {
            // eventName/w/stage/:eventID
            if (getEventDetails?.data?.eventType?.includes("Webinar")) {
              Navigate(`/${eventName}/w/${eventID}`);
            } else if (
              getEventDetails?.data?.eventType?.includes("Conference")
            ) {
              Navigate(`/${eventName}/conference/${eventID}`);
            } else {
              Navigate(`/${eventName}/exhibition/${eventID}`);
            }
            // else {
            //   Navigate(`/${eventName}/expo/stage/${eventID}`);
            // }
            // else {
            //   Navigate(`/${eventName}/c/${eventID}`);
            // }
          }}
          pending={false}
        />
        <SmBtn
          Title="Preview Event"
          BackgroundColor="#fff"
          ColorText="#1a87a3"
          BorderColor="#1a87a3"
          borderRadius={25}
          handleOnclick={() => {
            Navigate(`/${eventName}/event-landing/${eventID}`);
          }}
          pending={false}
        />
        <SmBtn
          Title="Delete Event"
          BackgroundColor="#fff"
          ColorText="#ff0000"
          BorderColor="#ff0000"
          borderRadius={25}
          handleOnclick={handleToggleDelete}
        />
        <ModalDelete
          size="md"
          isOpen={isToggleDelete}
          toggle={() => {
            setIsToggleDelete(false);
          }}
          title="Delete Event"
          body={
            <DeleteForm
              setIsToggleDelete={setIsToggleDelete}
              DeleteEvent={DeleteEvent}
              pending={mutationDelete?.isLoading}
            />
          }
        />
        <Modal size="md" isOpen={isToggleShare} toggle={handleShareEvent}>
          <ModalHeader toggle={handleShareEvent}>Share Event</ModalHeader>
          <ModalBody>
            <div className="d-flex gap-3">
              <CopyToClipboard
                text={`${process.env.React_APP_Frontend}${eventName?.replace(
                  / /g,
                  "-"
                )}/p/event-landing/${eventID}`}
                onCopy={() => setCopied(true)}
              >
                <button
                  type="button"
                  style={{
                    backgroundColor: "transparent",

                    borderStyle: "none",
                  }}
                >
                  {/* {CommunityLink?.substring(0, 50)} */}
                  {`${process.env.React_APP_Frontend}${eventName?.replace(
                    / /g,
                    "-"
                  )}
                /p/event-landing/${eventID}`.substring(0, 40)}
                </button>
              </CopyToClipboard>
              <div
                style={{
                  fontWeight: "700",
                  color: "black",
                  userSelect: "none",
                }}
              >
                <div className="d-flex gap-3">
                  <CopyToClipboard
                    text={`${
                      process.env.React_APP_Frontend
                    }${eventName?.replace(
                      / /g,
                      "-"
                    )}/p/event-landing/${eventID}`}
                    onCopy={() => setCopied(true)}
                  >
                    <CiLink size={25} color="black" />
                  </CopyToClipboard>
                  <span>
                    {copied ? (
                      <span className="mx-2" style={{ color: "#F68A20" }}>
                        Copied
                      </span>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(EventActions);

EventActions.propTypes = {
  t: PropTypes.any,
};
