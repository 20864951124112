import PropTypes from "prop-types";
import React, { useState } from "react";
import usePost from "../../Hooks/usePost";

import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

import ConfirmationMessage from "./components/ConfirmMessage/ConfirmationMessage";
import ForgotForm from "../../components/Authentication/ForgotForm";
import Slide from "../../components/Authentication/Slide";

import "./components/Login.scss";
const Login = (props) => {
  document.title = "Kago Events| Forgot Password";
  const { execute, pending, data } = usePost();
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleValidSubmit = async (e) => {
    e.preventDefault();
    const Method = "POST",
      endPoint = "auth/forgotPassword",
      token = null;
    const raw = JSON.stringify({
      email: inputs.email,
    });
    execute(endPoint, raw, Method, true, token);
  };

  if (data?.status === "success") {
    return (
      <div className="page-content">
        <ConfirmationMessage />
      </div>
    );
    // dispatch(loginUser(data, props.router.navigate));
  }

  return (
    <div
      className="authentication-container"
      style={{ backgroundColor: "#f9c393" }}
    >
      <div className="authentication-container-form">
        <div className="authentication--wrapper">
          <div className="login--slider-container d-flex  align-items-center">
            <div
              className="bg-primary login--slider-wrapper"
              style={
                {
                  // backgroundColor: "#F68A20",
                  // backgroundColor: "#dadada",
                }
              }
            >
              <Slide />
            </div>
          </div>
          <div className="p-2 login--form--slider-container d-flex">
            <div className="login--form--slider-container--first"></div>

            <div
              className="login--form--slider-container--second d-flex align-items-center"
              style={{
                userSelect: "none",
              }}
            >
              <div className="h-100 d-flex align-items-center">
                <div
                  className="w-100"
                  style={{
                    userSelect: "none",
                    //   backgroundColor: "yellow",
                  }}
                >
                  <div className="w-100 d-flex justify-content-center ">
                    <ForgotForm props={props} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="authentication-brandMark">
        <Link
          to="https://www.tngsolutions.co.za/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "black",
          }}
        >
          By TNG Solutions | Developed in South Africa
        </Link>
      </div>
    </div>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
