import CryptoJS from "crypto-js";

export const decryptDataInOneLine = (encryptedData, secretKey) => {
  const decodedData = CryptoJS.enc.Base64.parse(
    decodeURIComponent(encryptedData)
  ).toString(CryptoJS.enc.Utf8);

  const decryptedData = CryptoJS.AES.decrypt(decodedData, secretKey).toString(
    CryptoJS.enc.Utf8
  );

  return decryptedData;
};
