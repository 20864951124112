import React, { useState } from "react";
import { PiTicket } from "react-icons/pi";
import ButtonIcon from "../../../Buttons/ButtonIcon";
import OffCanvas from "../../../OffCanvas/OffCanvas";
import SearchInput from "../../../SerachInput/SearchInput";
import TableTicket from "./TableTicket";
import AddTicket from "./AddTicket";

const TicketingTab = ({ userID, token, eventID, eventDetails }) => {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleOpenOffCanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  return (
    <div className="w-100 px-5 py-4" style={{ paddingTop: "5%" }}>
      <div className="w-100">
        <div className="w-100">
          Manage and track all your tasks, issues, and requests efficiently.
          Each ticket set represents a collection of related tickets, allowing
          you to prioritize and organize your work effectively. Use the
          dashboard to view ticket statuses, assign tasks, and ensure timely
          resolutions. Streamline your workflow and keep your projects on track
          with our intuitive ticket management system.
        </div>
        <div className="w-100 mt-3 d-flex">
          <div className="w-75 d-flex">
            <SearchInput
              Title="Search"
              search={search}
              setSearch={setSearch}
              handleSearch={handleSearch}
              radius={25}
            />
          </div>
          <div className="w-25 d-flex justify-content-end">
            <ButtonIcon
              Title="Add Ticket"
              Icon={<PiTicket size={20} color="white" />}
              BackgroundColor="#f68a20"
              ColorText="white"
              BorderColor="#f68a20"
              borderRadius={20}
              handleOnclick={() => handleOpenOffCanvas()}
              pending={false}
            />
          </div>
        </div>
        <div>
          <TableTicket
            userID={userID}
            token={token}
            eventID={eventID}
            search={search}
            eventDetails={eventDetails}
          />
        </div>
      </div>
      <div className="w-100 mt-3"></div>
      <OffCanvas
        isOffcanvasOpen={isOffcanvasOpen}
        title="Add Ticket"
        body={
          <AddTicket
            userID={userID}
            token={token}
            eventID={eventID}
            eventDetails={eventDetails}
            setIsOffcanvasOpen={setIsOffcanvasOpen}
          />
        }
        toggleSaveChanges={handleOpenOffCanvas}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
    </div>
  );
};

export default TicketingTab;
