import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import useCustomMutation from "../../../../Hooks/useCustomMutation";
import ViewExhibitor from "./ViewExhibitor";
// import EditExhibitonBooths from "./EditExhibitonBooths";
import ModalDelete from "../../../Modal/ModalDelete";
import DeleteForm from "./DeleteForm";
import OffCanvas from "../../../OffCanvas/OffCanvas";
// import { FaEdit } from "react-icons/fa";
import { AiFillEye, AiFillDelete } from "react-icons/ai";

const TableBooths = ({ token, eventID, search, getAllBooths }) => {
  const [boothID, setBoothID] = useState(null);
  const [boothDetails, setBoothDetails] = useState(null);
  const [isToggleView, setIsToggleView] = useState(false);
  // const [isToggleEdit, setIsToggleEdit] = useState(false);
  const [isToggleDelete, setIsToggleDelete] = useState(false);
  const key = ["myBooths", eventID];

  const mutationDelete = useCustomMutation(key);

  const tog_viewParticipant = (details) => {
    setBoothDetails(details);
    setIsToggleView(!isToggleView);
  };
  // const tog_editParticipant = (details) => {
  //   setBoothDetails(details);
  //   setIsToggleEdit(!isToggleEdit);
  // };
  const tog_deleteParticipant = (id) => {
    setBoothID(id);
    setIsToggleDelete(!isToggleDelete);
  };

  const DeleteBooth = () => {
    const Method = "DELETE",
      url = `booths/${boothID}`,
      isJSON = null;
    var raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Exhibitor successfully deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Booth Logo</span>,
      cell: (row) => {
        return (
          <div
            className="d-flex align-items-center"
            style={{ width: 60, height: 60, borderRadius: 15 }}
          >
            <img
              src={row?.boothLogo}
              alt="booth-logo"
              className="w-100 h-100"
            />
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Booth Name</span>,
      selector: (row) => row?.boothName,
    },
    {
      name: <span className="font-weight-bold fs-13">Exhibitor Name</span>,
      selector: (row) => row?.boothExhibitor,
    },
    {
      name: <span className="font-weight-bold fs-13">Orgnaization Name</span>,
      selector: (row) => row?.organization,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div className="d-flex">
            <span>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => tog_viewParticipant(row)}
              />
            </span>
            {/* <span>
              <FaEdit
                size={18}
                className="mx-1"
                onClick={() => tog_editParticipant(row)}
              />
            </span> */}

            <span>
              <AiFillDelete
                size={20}
                onClick={() => tog_deleteParticipant(row?._id)}
              />
            </span>
          </div>
        );
      },
    },
  ];

  const filterBooths = (eventBoots, searchQuery) => {
    if (!searchQuery) return eventBoots;
    return eventBoots?.filter((item) =>
      [item?.boothName, item?.boothExhibitor, item?.organization].some(
        (field) => field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };
  const filteredEventBooths = filterBooths(getAllBooths?.data, search);

  const data = filteredEventBooths;
  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setIsToggleDelete(false);
    }
  }, [mutationDelete.isSuccess]);
  return (
    <div className="mt-3 w-100">
      <div className="analystic-table-card">
        <div className="w-100">
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </div>
      </div>
      <ModalDelete
        size="md"
        isOpen={isToggleDelete}
        toggle={() => {
          setIsToggleDelete(false);
        }}
        title="Delete Booth"
        body={
          <DeleteForm
            setIsToggleDelete={setIsToggleDelete}
            DeleteBooth={DeleteBooth}
            pending={mutationDelete?.isLoading}
          />
        }
      />
      <OffCanvas
        isOffcanvasOpen={isToggleView}
        title="Exhibitor Details"
        body={<ViewExhibitor details={boothDetails} />}
        toggleSaveChanges={() => tog_viewParticipant()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
      {/* <OffCanvas
        isOffcanvasOpen={isToggleEdit}
        title="Update Exhibitor Details"
        body={
          <EditExhibitonBooths
            details={exhibitorDetails}
            token={token}
            eventID={eventID}
            setIsToggleEdit={setIsToggleEdit}
            eventFormat={eventFormat}
          />
        }
        toggleSaveChanges={() => tog_editParticipant()}
        directionOffCanvas="end"
        widthOffCanvas={700}
      /> */}
    </div>
  );
};

export default TableBooths;
