import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

const Search = ({ search, setSearch }) => {
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  return (
    <div
      style={{
        width: 300,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          background: "transparent",
          paddingTop: ".58rem",
          paddingBottom: ".5rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          border: "solid",
          borderWidth: 0.1,
          borderRadius: 30,
          borderColor: "#f68a20",
          //   background: "white",
        }}
      >
        <AiOutlineSearch size={24} color="#f68a20" />
        <input
          type="text"
          // placeholder="Search event name"
          value={search}
          onChange={handleSearch}
          style={{
            width: "100%",
            border: "none",
            marginLeft: 8,
            paddingRight: 24,
            backgroundColor: "transparent",
            color: "#f68a20",
          }}
        />
      </div>
    </div>
  );
};

export default Search;
