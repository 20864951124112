import React, { useEffect, useState } from "react";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import { useParams } from "react-router-dom";

export default function JoinSessionHost() {
  document.title = "Kago Events | Live Session";
  const obj = JSON.parse(localStorage.getItem("authEventKagoUser"));
  const userId = obj?.data?.data?.id;
  const firstName = obj?.data?.data?.firstName;
  const lastName = obj?.data?.data?.lastName;
  const params = useParams();
  const sessionID = params.sessionID;
  const eventID = params.eventID;
  const eventName = params.eventName;
  const sessionName = params.sessionName;
  const mySessionName = sessionName.replace(/-/g, " ");
  const userFullName = `${firstName} ${lastName}`;
  const exitLink = `https://events.kago.digital/${eventName}/conference/${eventID}`;
  // console.log("exitLink...", exitLink);
  console.log("...userId...", userId);

  useEffect(() => {
    const apiKey = "b6d0b019-fd1d-4773-ae33-547dd07381d6";
    // const apiKey = process.env.REACT_APP_VIDEOSDK_API_KEY;
    const meetingId = `${sessionID}`;
    const name = `${userFullName}`;
    const config = {
      name: name,
      meetingId: meetingId,
      apiKey: apiKey,
      region: "sg001", // region for new meeting
      participantId: userId,
      theme: "LIGHT",
      //   containerId: null,
      redirectOnLeave: `${exitLink}`,
      micEnabled: true,
      webcamEnabled: true,
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,
      participantCanLeave: true, // if false, leave button won't be visible

      chatEnabled: true,
      screenShareEnabled: true,
      pollEnabled: true,
      whiteboardEnabled: true,
      raiseHandEnabled: true,

      recording: {
        autoStart: false, // auto start recording on participant joined
        enabled: true,
        webhookUrl: "https://www.videosdk.live/callback",
        awsDirPath: `/meeting-recordings/sessions/${meetingId}`, // automatically save recording in this s3 path
      },

      livestream: {
        autoStart: true,
        enabled: true,
      },

      layout: {
        type: "SPOTLIGHT", // "SPOTLIGHT" | "SIDEBAR" | "GRID"
        priority: "SPEAKER", // "SPEAKER" | "PIN",
        // gridSize: 3,
      },

      branding: {
        enabled: false,
        logoURL: null,
        name: mySessionName,
        poweredBy: false,
      },

      permissions: {
        pin: true,
        askToJoin: false, // Ask joined participants for entry in meeting
        canCreatePoll: true,
        toggleParticipantMic: true, // Can toggle other participant's mic
        toggleParticipantWebcam: true, // Can toggle other participant's webcam
        drawOnWhiteboard: true, // Can draw on whiteboard
        toggleWhiteboard: true, // Can toggle whiteboard
        toggleRecording: true, // Can toggle meeting recording
        toggleLivestream: true, //can toggle live stream
        removeParticipant: true, // Can remove participant
        endMeeting: true, // Can end meeting
        changeLayout: true, //can change layout
      },

      joinScreen: {
        visible: true, // Show the join screen ?
        title: mySessionName, // Meeting title
        // meetingUrl: window.location.href, // Meeting joining url
      },

      leftScreen: {
        // visible when redirect on leave not provieded
        actionButton: {
          // optional action button
          label: `${sessionName}`, // action button label
          href: `${exitLink}`, // action button href
        },
      },

      notificationSoundEnabled: true,

      debug: true, // pop up error during invalid config or netwrok error

      maxResolution: "hd", // "hd" or "sd"
      // you can change made to conference or viewer
      mode: "CONFERENCE",
      // For more features check: /prebuilt/guide/prebuilt-video-and-audio-calling/getting-started

      //session set time
      autoCloseConfig: {
        type: "session-end-and-deactivate",
        duration: 1,
      },
    };

    const meeting = new VideoSDKMeeting();
    meeting.init(config);
  }, []);

  return <div></div>;
}
