import React, { useState } from "react";
import BoothDetails from "./BoothDetails";
import BoothCompany from "./BoothCompany";
import useCustomFecthQuery from "../../../Hooks/useCustomFecthQuery";
import DataTable from "react-data-table-component";
import OffCanvas from "../../OffCanvas/OffCanvas";
import BoothNaviagtion from "./BoothNaviagtion";
import ViewReview from "./ViewReview";
import { AiFillEye } from "react-icons/ai";

const ReviewsBooth = ({ eventName, eventID, boothID }) => {
  const [search, setSearch] = useState("");
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [reviewDetails, setReviewDetails] = useState(null);

  // const key = ["myLeads", boothID];
  //  const {
  //    data: getLeads,
  //    error: errorGetLeads,
  //    isLoading: isLoadingGetLeads,
  //  } = useCustomFecthQuery(
  //    key,
  //    `${process.env.REACT_APP_API}speakers/event/${boothID}`,
  //    token,
  //    {
  //      enabled: !!boothID,
  //    }
  //  );

  const tog_viewReviews = (details) => {
    setReviewDetails(details);
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const toggleOpenOffCanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">First Name</span>,
      selector: (row) => row?.firstName,
    },
    {
      name: <span className="font-weight-bold fs-13">Last Name</span>,
      selector: (row) => row?.lastName,
    },
    {
      name: <span className="font-weight-bold fs-13">JobTitle</span>,
      selector: (row) => row?.jobTitle,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => tog_viewReviews(row)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const filterReviews = (reviews, searchQuery) => {
    if (!searchQuery) return reviews;
    return reviews?.filter((item) =>
      [item?.firstName, item?.lastName, item?.email, item?.jobTitle].some(
        (field) => field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };
  const boothReviewData = [];
  // const filteredSpeakers = filterParticipants(getLeads?.data, search);
  const filteredReviews = filterReviews(boothReviewData, search);
  const data = filteredReviews;

  return (
    <div className="set-event-rightbar-content">
      <div className="w-100 d-flex">
        <div
          className="px-4"
          style={{
            width: "100%",
            margin: "0px auto",
          }}
        >
          <BoothNaviagtion
            eventName={eventName}
            eventID={eventID}
            boothID={boothID}
          />
          <div className="analystic-table-card mt-4">
            <div className="w-100">
              <DataTable
                fixedHeader
                fixedHeaderScrollHeight="300px"
                columns={columns}
                responsive
                data={data}
                pagination
              />
            </div>
          </div>
        </div>
      </div>
      <OffCanvas
        isOffcanvasOpen={isOffcanvasOpen}
        title="View Review"
        body={
          <ViewReview
          // userID={userID}
          // token={token}
          // eventID={eventID}
          />
        }
        toggleSaveChanges={toggleOpenOffCanvas}
        directionOffCanvas="end"
        widthOffCanvas={700}
      />
    </div>
  );
};

export default ReviewsBooth;
